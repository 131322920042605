import { call, put, takeLeading } from 'redux-saga/effects';
import Sftps from 'services/sftps';
import {
    createSftpRequested,
    startSftpSucceeded,
    startSftpFailed,
} from './sftpSlice';

export function* handleStartSftp({ payload }) {
    try {
        const { data } = yield call(Sftps.start, payload);

        yield put(startSftpSucceeded(data));
    } catch (error) {
        let response = error.response;
        let apiError;
        if (typeof response.data !== "undefined" && response.data) {
            apiError = response.data;
        } else {
            apiError = error.message;
        }
        yield put(startSftpFailed(apiError));
    }
}

const watchers = [
    takeLeading(createSftpRequested.type, handleStartSftp),
];

export default watchers;
