import axios from "axios";
import { services } from "configs/api";
import { toSnakeCaseKeys } from "common/helpers/utils";

const Functions = {
  release(repo, oldVersion) {
    return axios.post(
      services.functions(`release`),
      toSnakeCaseKeys({
        repoName: repo,
        oldVersion
      })
    );
  }
};

export default Functions;
