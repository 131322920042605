import React from 'react';
import { useSelector } from 'react-redux';
import { makeStyles } from '@mui/styles';
import { Typography, Grid } from '@mui/material';

import {
    selectUserProfile,
} from 'components/App/appSlice';

const useStyles = makeStyles((theme) => ({
    container: {
        marginTop: theme.spacing(4)
    },
}));


const Dashboard = () => {
    const classes = useStyles();
    const userProfile = useSelector(selectUserProfile);
    return (
        <Grid container justifyContent="center" className={classes.container}>
            <Typography variant="h3">
                Welcome {userProfile.given_name} {userProfile.family_name}    
            </Typography>
        </Grid>
    );
}

export default Dashboard;