import { call, put, delay, select, takeLeading, takeLatest } from 'redux-saga/effects';

import Ondemand from 'services/ondemand';
import {
  fetchOndemandRequested,
  fetchOndemandFailed,
  fetchOndemandSucceeded,
  createOndemandRequested,
  createOndemandFailed,
  createOndemandSucceeded,
  selectOndemandSite
} from './ondemandSlice';


export function* handleFetchOndemand() {
  try {
    const ondemandSite = yield select(selectOndemandSite);
    if(ondemandSite) {
      const { data } = yield call(Ondemand.get, ondemandSite.SK);
      if(data 
        && data.state === 'complete'
        && data.data_transfer_status === 'complete'
        && data.be_pipeline_status === 'Succeeded'
        && data.fe_pipeline_status === 'Succeeded'
        ) {
        yield put(
          fetchOndemandSucceeded(data)
        );
        return;
      } else if(data) {
        if(data.state === 'error') {
          yield put(
            fetchOndemandFailed(`Ondemand Creation has failed! Error ${data.error}`)
          );
          return;
        } else if(data.be_pipeline_status === 'Failed' || data.fe_pipeline_status === 'Failed') {
          yield put(
            fetchOndemandFailed('Ondemand Creation has failed! Error: Pipeline execution has failed')
          );
          return;
        }
      }
    }
    yield delay(20000);

    yield put(
      fetchOndemandRequested()
    );
  } catch (error) {
    let response = error.response;
    let apiError;
    if (response.data) {
      apiError = response.data;
    } else {
      apiError = error.message;
    }
    yield put(fetchOndemandFailed(apiError));
  }
}

export function* handleCreateOndemand({ payload }) {
  try {
    const { data } = yield call(Ondemand.create, payload);
    yield put(
      createOndemandSucceeded(data),
    );
  } catch (error) {
    let response = error.response;
    let apiError;
    if (typeof response.data !== 'undefined' 
      && response.data) {
      apiError = response.data;
    } else {
      apiError = error.message;
    }
    yield put(createOndemandFailed(apiError));
  }
}

const watchers = [
  takeLatest(fetchOndemandRequested.type, handleFetchOndemand),
  takeLeading(createOndemandRequested.type, handleCreateOndemand),
];

export default watchers;
