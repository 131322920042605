import { createSlice } from '@reduxjs/toolkit';


export const initialState = {
    isSaving: false,
    isFetching: false,
    isFinishedCreating: true,
    ondemandSite: {},
    error: null,
    succeeded: false,
};

export const slice = createSlice({
  name: 'ondemand',
  initialState,
  reducers: {
    fetchOndemandRequested: (state) => {
      state.isFetching = true;
      state.error = null;
    },
    fetchOndemandFailed: (state, action) => {
      state.isFetching = false;
      state.error = action.payload;
    },
    fetchOndemandSucceeded: (state, action) => {
      state.isFetching = false;
      state.isFinishedCreating = true;
      state.error = null;
      state.ondemandSite = action.payload;
    },
    createOndemandRequested: (state) => {
      state.isSaving = true;
      state.error = null;
      state.succeeded = false;
    },
    createOndemandFailed: (state, action) => {
      state.isSaving = false;
      state.error = action.payload;
      state.succeeded = false;
      state.isFinishedCreating = true;
    },
    createOndemandSucceeded: (state, action) => {
      state.isSaving = false;
      state.error = null;
      state.succeeded = true;
      state.isFinishedCreating = false;
      state.ondemandSite = action.payload;
    },
    resetSaveState: (state) => {
      state.isSaving = false;
      state.isFetching = false;
      state.error = null;
      state.succeeded = false;
    }
  }
});

// Actions
export const {
  fetchOndemandRequested,
  fetchOndemandFailed,
  fetchOndemandSucceeded,
  createOndemandRequested,
  createOndemandFailed,
  createOndemandSucceeded,
  resetSaveState,
} = slice.actions;

// Selectors
export const selectIsSaving = (state) => state.ondemand.isSaving;
export const selectSucceeded = (state) => state.ondemand.succeeded;
export const selectError = (state) => state.ondemand.error;
export const selectIsFetching = (state) => state.ondemand.isFetching;
export const selectIsFinishedCreating = (state) => state.ondemand.isFinishedCreating;
export const selectOndemandSite = (state) => state.ondemand.ondemandSite;

export default slice.reducer;
