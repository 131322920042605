import { combineReducers } from "redux";

import appReducer from "components/App/appSlice";
import branchesReducers from "stores/branches/branchesSlice";
import churnReducers from "stores/churn/churnSlice";
import dataTransfersReducers from "stores/dataTransfers/dataTransfersSlice";
import ondemandReducers from "stores/ondemand/ondemandSlice";
import orgReducers from "stores/organizations/organizationsSlice";
import sftpReducers from "stores/sftp/sftpSlice";
import functionsReducers from "stores/functions/functionsSlice";

export default combineReducers({
  app: appReducer,
  branches: branchesReducers,
  churn: churnReducers,
  dataTransfers: dataTransfersReducers,
  ondemand: ondemandReducers,
  organizations: orgReducers,
  sftp: sftpReducers,
  functions: functionsReducers
});
