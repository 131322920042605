import { call, put, takeEvery } from "redux-saga/effects";

import Functions from "services/functions";
import {
  releaseRequested,
  releaseFailed,
  releaseSucceeded
} from "./functionsSlice";

export function* handleStartRelease({ payload }) {
  try {
    const { data } = yield call(
      Functions.release,
      payload["repo"],
      payload["oldVersion"]
    );

    yield put(releaseSucceeded(payload["repo"]));
  } catch (error) {
    yield put(
      releaseFailed({ error: error.errorMessage, repo: payload["repo"] })
    );
  }
}

const watchers = [takeEvery(releaseRequested.type, handleStartRelease)];

export default watchers;
