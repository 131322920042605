import { configureStore } from '@reduxjs/toolkit';
import createSagaMiddleware from 'redux-saga';
import rootReducer from 'configs/reducers';
import rootSaga from 'configs/sagas';

import { saveState } from '../common/helpers/localStorage';

// Redux middlewares
const sagaMiddleware = createSagaMiddleware();
// Make sure to add your middlewares here
const middleware = [sagaMiddleware];

const store = configureStore({
  reducer: rootReducer,
  middleware,
  devtools: process.env.NODE_ENV !== 'production',
});

store.subscribe(() => {
  saveState(store.getState())
});

sagaMiddleware.run(rootSaga);

export default store;
