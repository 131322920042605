import { makeStyles } from '@mui/styles';
import { alpha } from '@mui/material/styles';

const Style = makeStyles((theme) => ({
  button: {
    padding: `calc(${theme.spacing(2)} - 2px) ${theme.spacing(3)}`,
  },
  progressIcon: {
    color: alpha(theme.palette.primary.contrastText, 0.9),
  },
}));

export default Style;
