import {
    call, put,
} from 'redux-saga/effects';


import {
    startBoot,
    completeBoot,
    setUserProfile
} from './appSlice';

import { loadApiConfig, unauthorizeInterceptor } from 'configs/api';
import Authentication from 'services/authentication';


export function* initiateBoot() {
    yield put(startBoot());
    yield call(loadApiConfig);
    yield call(unauthorizeInterceptor);
    yield call(pullUserInformation);
    // pull the user info in between this.
    yield put(completeBoot());
}

function* pullUserInformation() {
    const userInfo = yield call(Authentication.userProfile);
    yield put(setUserProfile(userInfo.data));
}

const appSagas = [];
  
export default appSagas;
  