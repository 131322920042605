import axios from 'axios';
import { services } from 'configs/api';
import { toSnakeCaseKeys } from 'common/helpers/utils';

const Ondemand = {
  get(id) {
    return axios.get(services.ondemand(id));
  },
  create(payload) {
    return axios.post(services.ondemand(''), toSnakeCaseKeys(payload));
  }
};

export default Ondemand;
