import React from 'react';
import { makeStyles } from '@mui/styles';
import {
    Typography, Link,
    Grid, Button,
    FormControl,
    Input, Checkbox,
    FormGroup, FormControlLabel,
    FormHelperText, InputLabel,
    Select, MenuItem, CircularProgress,
    LinearProgress, Alert
} from '@mui/material';

import {
    saveOrganizationRequested, resetSaveState,
    selectIsSaving, selectError, selectSucceeded,
    fetchOrganizationRequested,
    selectIsFinishedCreating
} from 'stores/organizations/organizationsSlice';
import { useDispatch, useSelector } from 'react-redux';
import Environments from 'services/environments';

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        overflow: 'none',
        marginTop: theme.spacing(4),
    },
    container: {
        '& .MuiFormControl-root': {
            paddingRight: theme.spacing(2),
            width: '100%',
            paddingBottom: '24px',
        },
    },
    submitButton: {
        float: "right"
    }
}));

const initialFormState = {
    environment: '',
    organization_name: '',
    subdomain: '',
    school_years: []
}

const CreateOrganization = () => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const isSaving = useSelector(selectIsSaving);
    const error = useSelector(selectError);
    const savedSucceeded = useSelector(selectSucceeded);
    const isFinishedCreating = useSelector(selectIsFinishedCreating);
    const school_years = [];

    for (let year = 2018; year <= new Date().getFullYear() + 6; year++) {
        school_years.push(year);
    }

    const fieldValidations = {
        environment: {
            required: true
        },
        organization_name: {
            required: true
        },
        subdomain: {
            required: true
        },
        school_years: {
            required: true
        }
    };

    const [formValues, setFormValues] = React.useState(initialFormState);

    const [formErrors, setFormErrors] = React.useState({environment: [], organization_name: [], subdomain: [], school_years: []});
    
    let link = '';
    if (formValues.environment !== '') {
        link = Environments[formValues.environment].replace('subdomain', formValues.subdomain);
    }

    const handleFieldChange = (input, newValue) => {
        const updatedformValues = {
            ...formValues,
            [input]: newValue
        };
        setFormValues(updatedformValues);
    };

    const handleOptionChange = (input, event) => {
        const { name, checked } = event.target;
    
        const existingValues = formValues[input];

        if (checked) {
            existingValues.push(name);
        } else {
            var index = existingValues.indexOf(name);
            if (index !== -1) existingValues.splice(index, 1);
        }
        
        setFormValues({ ...formValues, [input]: existingValues });
    };

    const validateValue = (field) => {
        let errors = [];
        if (fieldValidations[field].required && (!formValues[field] || !formValues[field].toString().trim().length)) {
            errors.push('required');
        }

        return errors;
    }

    const validateFormField = (key) => {
        let formErrorsTemp = { ...formErrors };
        formErrorsTemp[key] = validateValue(key);
        setFormErrors(formErrorsTemp);
    }

    const getErrorBanner = () => {
        if (error) {
            return (
                <Alert severity="error">
                    <pre>{JSON.stringify(error, null, 2) }</pre>
                </Alert>
            );
        }
    }

    const getSuccessBanner= () => {
        if (savedSucceeded && isFinishedCreating) {
            return (
                <Alert severity="success">
                    Organization Created Successfully, visit: <Link target="_blank" href={link}>{link}</Link>
                </Alert>
            );
        }
    }

    const getProgressBanner= () => {
        if (!isFinishedCreating) {
            return (
                <>
                    <Alert severity="info">
                        Organization is being created.
                    </Alert>
                    <LinearProgress/>
                </>
            );
        }
    }

    React.useEffect(() => {
        if (!isFinishedCreating) {
            setTimeout(() => dispatch(fetchOrganizationRequested()), 5000);
        }
    }, [dispatch, isFinishedCreating]);

    React.useEffect(() => () => {
        dispatch(resetSaveState());
    }, [dispatch]);

    /**
     * this piece should be taken to refactor to perhaps its own class
     */
    const validateformValues = () => {
        let valid = true;
        let formErrorsTemp = { ...formErrors };
        Object.keys(formValues).forEach(function(key) {
            formErrorsTemp[key] = validateValue(key);
        });
        setFormErrors(formErrorsTemp);

        Object.keys(formErrorsTemp).forEach(function(key) {
            if (!!formErrorsTemp[key].length) {
                valid = false;
            }
        });

        return valid;
    }
    // dispatch(saveOrganizationRequested(formValues));
    const handleCreateOrganization = (event) => {
        event.preventDefault();
        const valid = validateformValues();
        if (valid) {
            dispatch(saveOrganizationRequested(formValues));
        }
    }                                                                                                                                                                                                                                                                                                                                              

    return (
        <div className={classes.root}>
            <Grid container justify-xs-center="true" direction="row" className={classes.container}>
                <Grid container alignItems="center" justifyContent="center">
                    <Grid item lg={6} sm={12}>
                        {getErrorBanner()}
                        {getSuccessBanner()}
                        {getProgressBanner()}
                    </Grid>
                </Grid>

                <Grid item lg={3} xs={1}></Grid>
                <Grid item lg={3} xs={10}>
                    <Typography variant="h5" color="primary">
                        Create New Organization
                    </Typography>
                </Grid>
            </Grid>
            <Grid container alignItems="center" direction="row" className={classes.container}>
                <Grid item lg={3} xs={1}></Grid>
                <Grid item lg={6} xs={10}>
                    <FormControl>
                        <InputLabel htmlFor="environment" error={!!formErrors.environment.length}>Environment</InputLabel>
                         <Select
                            variant='standard'
                            labelId="environment_description"
                            id="environment"
                            error={!!formErrors.environment.length}
                            value={formValues.environment}
                            onChange={(e) => handleFieldChange('environment', e.target.value)}
                            onBlur={(e) => validateFormField('environment')}
                            >
                            <MenuItem value="">
                                <em>None</em>
                            </MenuItem>
                            {Object.keys(Environments).map((environment) => (
                                <MenuItem key={environment} value={environment}>{environment}</MenuItem>
                            ))}
                        </Select>
                        <FormHelperText id="environment_description" error={!!formErrors.environment.length}>Environment where to create the organization</FormHelperText>
                    </FormControl>
                </Grid>
            </Grid>
            <Grid container direction="row" className={classes.container}>
                <Grid item lg={3} xs={1}></Grid>
                <Grid item xs={10} lg={6}>
                    <FormControl>
                        <InputLabel htmlFor="organization_name" error={!!formErrors.organization_name.length}>Organization Name</InputLabel>
                        <Input
                            id="organization_name"
                            required
                            variant="outlined"
                            onBlur={(e) => validateFormField('organization_name')}
                            error={!!formErrors.organization_name.length}
                            aria-describedby="organization_name_description"
                            value={formValues.organization_name}
                            onChange={(e) => handleFieldChange('organization_name', e.target.value)}
                        />
                        <FormHelperText id="organization_name_description" error={!!formErrors.organization_name.length}>Display name for the customer</FormHelperText>
                    </FormControl>
                </Grid>
            </Grid>

            <Grid container direction="row" className={classes.container}>
                <Grid item lg={3} xs={1}></Grid>
                <Grid item lg={6} xs={10}>
                    <FormControl>
                        <InputLabel htmlFor="subdomain" error={!!formErrors.subdomain.length}>Subdomain</InputLabel>
                        <Input
                            id="subdomain"
                            variant="outlined"
                            error={!!formErrors.subdomain.length}
                            aria-describedby="subdomain_description"
                            value={formValues.subdomain}
                            onBlur={(e) => validateFormField('subdomain')}
                            onChange={(e) => handleFieldChange('subdomain', e.target.value)}
                            lg={12}
                        />
                        <FormHelperText id="subdomain_description" error={!!formErrors.subdomain.length}>Unique subdomain for the url: {link} </FormHelperText> 
                    </FormControl>
                </Grid>
            </Grid>

            
            <Grid container direction="row" className={classes.container}>
                <Grid item lg={3} xs={1}></Grid>
                <Grid item lg={6} xs={10}>
                    <InputLabel error={!!formErrors.school_years.length}>Active Years</InputLabel>
                    <FormControl component="fieldset">
                        <FormGroup row>
                            {school_years.map((year) => (
                            <FormControlLabel
                                key={year}
                                control={(
                                <Checkbox
                                    checked={formValues.school_years[year]}
                                    onChange={(e) => handleOptionChange('school_years', e)}
                                    name={year}
                                    color="primary"
                                />)}
                                label={year}
                            />
                            ))}
                        </FormGroup>
                    </FormControl>
                </Grid>

                <Grid container>
                    <Grid item lg={9} xs={11}>
                        <Button 
                            className={classes.submitButton}
                            color="primary" variant="contained"
                            onClick={handleCreateOrganization}
                        >
                            {isSaving && <CircularProgress color="inherit" size="1rem" /> } Create Organization
                        </Button>
                    </Grid>
                </Grid>
            </Grid>     
        </div>
    );
}

export default CreateOrganization;
