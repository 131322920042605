import './App.css';

import React from 'react';
import { useSelector } from 'react-redux';
import { ThemeProvider, StyledEngineProvider, createTheme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';


import Layout from 'components/common/Layout';
import { BrowserRouter } from 'react-router-dom';
import history from 'configs/history';
import URIs from 'configs/URIs';

import {
  selectisBooting
} from './appSlice';

export const typography = {
  fontFamily: 'Roboto',
  h1: {
    fontSize: '3rem',
    fontFamily: 'Raleway',
    fontWeight: 300,
  },
  h4: {
    fontSize: '1.625rem',
    fontFamily: 'Raleway',
    fontWeight: 600,
  },
  h5: {
    fontSize: '1.375rem',
    fontFamily: 'Raleway',
    fontWeight: 600,
  },
  subtitle1: {
    fontSize: '0.875rem',
    fontWeight: 600,
  },
  button: {
    textTransform: 'none',
  },
  body2: {
    fontSize: '0.875rem',
    fontWeight: 'normal',
  },
};

const theme = createTheme({
  typography,
  palette: {
    text: {
      primary: '#072044',
      secondary: '#336d86',
    },
    primary: {
      main: '#009EAA',
      color: '#ffffff',
    },
    secondary: {
      main: '#072044',
      light: '#004968',
    },
    error: {
      main: '#EC5A5A',
    },
    background: {
      default: '#ffffff',
    },
    textPrimary: {
      default: '#0f0',
    },
    success: {
      main: '#6ec05d',
    }
  },
});

function App() {
  const isBooting = useSelector(selectisBooting);
  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <BrowserRouter history={history}>
          <Layout isLoading={isBooting}>
            <URIs />
          </Layout>
        </BrowserRouter>
      </ThemeProvider>
    </StyledEngineProvider>
  );
}

export default App;
