import { get as _get } from 'lodash';

export const loadState = (key) => {
    try {
        const serializedState = localStorage.getItem('state');
        if (serializedState === null) {
            return undefined;
        }

        if (key === null || typeof key === 'undefined') {
            return JSON.parse(serializedState);
        } else {
            const state = JSON.parse(serializedState);
            return _get(state, key, null);
        }
        
    } catch(err) {
        return undefined;
    }
};

export const saveState = (state) => {
    try {
        const serializedState = JSON.stringify(state);
        localStorage.setItem('state', serializedState);
    } catch (err) {
        // Ignore write err
    }
};