import { fork, all } from "redux-saga/effects";

import appSaga, { initiateBoot } from "components/App/appSaga";
import branchesSaga from "stores/branches/branchesSaga";
import churnSaga from "stores/churn/churnSaga";
import dataTransfersSaga from "stores/dataTransfers/dataTransfersSaga";
import ondemandSaga from "stores/ondemand/ondemandSaga";
import organizationSaga from "stores/organizations/organizationsSaga";
import sftpSaga from "stores/sftp/sftpSaga";
import functionsSaga from "stores/functions/functionsSaga";

export default function* rootSaga() {
  yield all([
    fork(initiateBoot),
    ...appSaga,
    ...branchesSaga,
    ...churnSaga,
    ...dataTransfersSaga,
    ...ondemandSaga,
    ...organizationSaga,
    ...sftpSaga,
    ...functionsSaga
  ]);
}
