import React from "react";

import { Route, Routes, Navigate } from "react-router-dom";
import { useSelector } from "react-redux";

import { selectIsUserAuthenticated } from "components/App/appSlice";

import Dashboard from "components/Dashboard/Dashboard";
import CreateOrganization from "components/Organizations/CreateOrganization";
import CreateEnvironment from "components/Environments/CreateEnvironment";
import StartDataTransfer from "components/DataTransfer/StartDataTransfer";

import Signin from "components/Signin/Signin";
import ChurnOrganization from "components/Organizations/ChurnOrganization";
import RouteGuard from "components/common/RouteGuard";
import SftpAccount from "components/Sftp/sftp_account";
import StartRelease from "components/Release/ReleaseForm";

// Very simple non authenticated approach
// only signin route is public, all others are private
const URIs = () => {
  const isAuthenticated = useSelector(selectIsUserAuthenticated);
  return (
    <React.Suspense fallback={<div>Loading...</div>}>
      <Routes>
        {!isAuthenticated && <Route exact path="/" element={<Signin />} />}
        {isAuthenticated && (
          <>
            <Route exact path="/" element={<Dashboard />} />
            <Route
              element={<RouteGuard fallBackRoute={"/"} mod={"functions"} />}
            >
              <Route exact path="/release" element={<StartRelease />} />
            </Route>
            <Route exact path="/sftp" element={<SftpAccount />} />
            <Route
              exact
              path="/data-transfer"
              element={<StartDataTransfer />}
            />
            <Route exact path="/environments" element={<CreateEnvironment />} />
            <Route
              exact
              path="/organizations/create"
              element={<CreateOrganization />}
            />
            <Route element={<RouteGuard fallBackRoute={"/"} mod={"churn"} />}>
              <Route
                exact
                path="/organizations/churn"
                element={<ChurnOrganization />}
              />
            </Route>
          </>
        )}
        <Route path="*" render={() => <Navigate to="/" />} />
      </Routes>
    </React.Suspense>
  );
};

export default URIs;
