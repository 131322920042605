import { isEmpty } from "lodash";

export const toSnakeCase = (e) => e
  && e
    .match(/[A-Z]{2,}(?=[A-Z][a-z]+[0-9]*|\b)|[A-Z]?[a-z]+[0-9]*|[A-Z]|[0-9]+/g)
    .map((x) => x.toLowerCase())
    .join('_');

export const toSnakeCaseKeys = (obj) => {
    const newObject = {};
    Object.entries(obj).forEach(([key, value]) => {
        if (isEmpty(value) || value.constructor !== Object) {
            newObject[toSnakeCase(key)] = value;
        } else {
            const newValue = toSnakeCaseKeys(value);
            newObject[toSnakeCase(key)] = newValue;
        }
    });
    return newObject;
};

// Because of Safari format, we need to
// Change this due to safari having issues creating new dates with -
export const toDateTime = (dateString, dateSeparator = '-') => {
    const dateTimeParts = dateString.toString().includes('T') ? dateString.toString().split('T') : dateString.toString().split(' ');
    // 0 -> date
    // 1 -> hour
    const dateParts = dateTimeParts[0].split(dateSeparator);
    // 0 -> hour
    // 1 -> minutes
    // 2 -> seconds
    let timeParts = ['00', '00', '00'];
    if (dateTimeParts.length > 1) {
        timeParts = dateTimeParts[1].split(':');
    }

    return new Date(dateParts[0], dateParts[1] - 1, dateParts[2], timeParts[0], timeParts[1], (timeParts[2] || '00').split('.')[0]);
};