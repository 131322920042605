import { createSlice } from '@reduxjs/toolkit';
import { loadState } from 'common/helpers/localStorage';

export const initialState = {
    isSaving: false,
    isFetching: false,
    isFinished: true,
    fetching: [],
    organizations: {},
    error: null,
    succeeded: false,
    environmentDatabases: loadState('dataTransfers.environmentDatabases') ?? {},
    isEnvironmentDatabasesLoaded: false,
    transferId: "",
    transferStatus: ''
};

export const slice = createSlice({
  name: 'dataTransfers',
  initialState,
  reducers: {
    startDataTransferRequested: (state) => {
      state.isSaving = true;
      state.error = null;
      state.succeeded = false;
      state.isFinished = false;
      state.transferId = "";
      state.transferStatus = "";
    },
    startDataTransferFailed: (state, action) => {
      state.isSaving = false;
      state.error = action.payload;
      state.succeeded = false;
      state.isFinished = true;
    },
    startDataTransferSucceeded: (state, action) => {
      state.isSaving = false;
      state.error = null;
      state.succeeded = true;
      state.isFinished = false;
      const transfer = action.payload;
      state.transferStatus = transfer.status;
      state.transferId = transfer.SK;
    },
    listDatabasesRequested: (state) => {
      state.isFetching = true;
      state.error = null;
      state.fetching.push(true);
    },
    listDatabasesFailed: (state, action) => {
      state.isFetching = false;
      state.error = action.payload;
      state.fetching.pop();
    },
    listDatabasesSucceeded: (state, action) => {
      state.isFetching = false;
      state.error = null;
      state.fetching.pop();
      const { environment, databases } = action.payload
      state.environmentDatabases[environment] = databases
    },
    fetchDataTransferStatusRequested: (state) => {
      state.isFetching = true;
      state.error = null;
    },
    fetchDataTransferStatusFailed: (state, action) => {
      state.isFetching = false;
      state.error = action.payload;
      state.isFinished = true;
    },
    fetchDataTransferStatusSucceeded: (state, action) => {
      state.isFetching = false;
      state.error = null;
      state.succeeded = true;
      const { status } = action.payload;
      state.transferStatus = status;
      state.isFinished = true;
    },
    resetEnvironmentDatabases: (state) => {
      state.environmentDatabases = {};
      state.isEnvironmentDatabasesLoaded = false;
    },
    resetSaveState: (state) => {
      state.error = null;
      state.isSaving = false;
      state.succeeded = false;
    }
  }
});

// Actions
export const {
  startDataTransferRequested,
  startDataTransferFailed,
  startDataTransferSucceeded,
  listDatabasesRequested,
  listDatabasesFailed,
  listDatabasesSucceeded,
  fetchDataTransferStatusRequested,
  fetchDataTransferStatusFailed,
  fetchDataTransferStatusSucceeded,
  resetSaveState,
  resetEnvironmentDatabases
} = slice.actions;

// Selectors
export const selectSucceeded = (state) => state.dataTransfers.succeeded;
export const selectError = (state) => state.dataTransfers.error;
export const selectIsFinished = (state) => state.dataTransfers.isFinished;
export const selectIsSaving = (state) => state.dataTransfers.isSaving;
export const selectEnvironmentDatabases = (state) => state.dataTransfers.environmentDatabases;
export const selectIsEnvironmentDatabasesLoaded = (state) => Object.keys(state.dataTransfers.environmentDatabases).length > 0 && state.dataTransfers.fetching.length === 0;
export const selectTransferId = (state) => state.dataTransfers.transferId;

export default slice.reducer;
