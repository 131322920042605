import React from "react";
import { makeStyles } from "@mui/styles";
import {
  Button,
  Toolbar,
  AppBar,
  Link,
  Grid,
  Typography,
  Menu,
  MenuItem
} from "@mui/material";
import { getBaseUrl, services } from "configs/api";
import { Link as RouterLink } from "react-router-dom";
import {
  CloudSync as CloudSyncIcon,
  Cloud as CloudIcon,
  Domain as DomainIcon,
  ExitToApp as ExitToAppIcon,
  Add as AddIcon,
  Storage as ServerIcon,
  Remove as RemoveIcon,
  Mediation as MediationIcon
} from "@mui/icons-material";
import logo from "assets/schoolmint-logo.svg";
import NavLink from "components/NavLink";
import useUser from "hooks/useUser";

const useStyles = makeStyles((theme) => ({
  subheaderContainer: {
    paddingTop: "14px",
    paddingBottom: "14px"
  },
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.secondary.main
  },
  appBar: {
    backgroundColor: theme.palette.secondary.main
  },
  menuButton: {
    marginRight: theme.spacing(2)
  },
  title: {
    flexGrow: 1
  },
  gridItemHover: {
    padding: "0.25rem 0.75rem",
    "&:hover": {
      backgroundColor: "#ffffff1A"
    }
  },
  gridContainer: {
    // marginTop: '-35px',
    // marginBottom: '-40px',
  },
  navButton: {
    color: "#fff",
    display: "block",
    padding: 0
  }
}));

export default function Header() {
  const classes = useStyles();
  const user = useUser();

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const organizationMenu = () => {
    let navLinks = [
      {
        to: "/organizations/create",
        icon: <AddIcon />,
        text: "Create Org"
      }
    ];
    if (user.canUseModule("churn")) {
      navLinks.push({
        to: "/organizations/churn",
        icon: <RemoveIcon />,
        text: "Churn Org"
      });
    }
    const menuItems = navLinks.map(({ to, icon, text }, index) => {
      return (
        <MenuItem onClick={handleClose} key={index}>
          <NavLink to={to} icon={icon} text={text} />
        </MenuItem>
      );
    });
    return (
      <Menu
        id="nav-menu1"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        {menuItems}
      </Menu>
    );
  };

  return (
    <div className={classes.root}>
      <AppBar position="static" className={classes.appBar}>
        <Toolbar>
          <Grid
            container
            justifyContent="space-between"
            direction="row"
            alignItems="center"
            color="secondary"
            className={classes.subheaderContainer}
          >
            <Grid item xs={4}>
              <Link underline="none" to="/" component={RouterLink}>
                <img src={logo} alt="org-logo" />
              </Link>
            </Grid>
            <Grid container item xs={8} justifyContent="flex-end">
              <Grid
                container
                flexdirection="row"
                justifyContent="flex-end"
                className={classes.gridContainer}
              >
                {user.canUseModule("functions") ? (
                  <Grid item className={classes.gridItemHover}>
                    <NavLink
                      to="/release"
                      icon={<MediationIcon />}
                      text="Release"
                    />
                  </Grid>
                ) : (
                  <></>
                )}

                <Grid item className={classes.gridItemHover}>
                  <NavLink to="/sftp" icon={<ServerIcon />} text="SFTP" />
                </Grid>

                <Grid item className={classes.gridItemHover}>
                  <NavLink
                    to="/data-transfer"
                    icon={<CloudSyncIcon />}
                    text="Data Transfer"
                  />
                </Grid>

                <Grid item className={classes.gridItemHover}>
                  <NavLink
                    to="/environments"
                    icon={<CloudIcon />}
                    text="Environments"
                  />
                </Grid>

                <Grid item className={classes.gridItemHover}>
                  <Button
                    id="nav-button1"
                    onClick={handleClick}
                    className={classes.navButton}
                  >
                    <Grid container justifyContent="center">
                      <Grid item>
                        <DomainIcon />
                      </Grid>
                    </Grid>
                    <Grid container justifyContent="center">
                      <Grid item>
                        <Typography variant="subtitle1">
                          Organizations
                        </Typography>
                      </Grid>
                    </Grid>
                  </Button>
                  {organizationMenu()}
                </Grid>

                <Grid item className={classes.gridItemHover}>
                  <Link
                    underline="none"
                    href={`${getBaseUrl()}${services.auth("sso/logout")}`}
                    color="inherit"
                  >
                    <Grid container justifyContent="center">
                      <Grid item>
                        <ExitToAppIcon />
                      </Grid>
                    </Grid>
                    <Typography variant="subtitle1">Log Out</Typography>
                  </Link>
                </Grid>

                {/* <Button color="inherit" href={`${getBaseUrl()}${services.api('sso/logout')}`}>Log Out</Button> */}
              </Grid>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
    </div>
  );
}
