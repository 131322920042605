import axios from 'axios';
import { services } from 'configs/api';
import { vpcForEnvironment } from './environments';


const Organizations = {
  all(product, env) {
    if ('production' === vpcForEnvironment(env)) {
      return axios.get(services.dataTransferProduction(`organizations/${product}/${env}`));
    } else {
      return axios.get(services.dataTransferInternal(`organizations/${product}/${env}`));
    }
  },
  get(env, subdomain) {
    return axios.get(services.enroll(env, `core/v1/internal/organizations/${subdomain}`));
  },
  create(payload) {
    const env = payload['environment']
    return axios.post(services.enroll(env, 'core/v1/internal/organizations'), payload);
  }
};

export default Organizations;
