import React from "react";
import { Controller } from "react-hook-form";
import PropTypes from "prop-types";
import { get as _get } from "lodash";
import {
  Box,
  FormControlLabel,
  FormHelperText,
  RadioGroup,
  Radio,
} from "@mui/material";
import useStyles from "./style";


function FormRadioGroup({
  name,
  defaultValue,
  label,
  options,
  control,
  errors,
  onChange: onChangeProp,
  isDisabled,
  backgroundColor,
}) {
  const classes = useStyles();
  const isError = _get(errors, name) !== undefined;

  const radioOptions = () => {
    return options.map((option) => (
      <FormControlLabel
        key={option.value}
        value={option.value}
        label={option.label}
        control={<Radio />}
      />
    ));
  };

  return (
    <Box>
      <FormControlLabel
        control={
          <Controller
            name={name}
            control={control}
            defaultValue={defaultValue || null}
            // onFocus={() => {
            //   inputRef.current.focus();
            // }}
            render={({ name, onBlur, onChange, value }) => {
              return (
                <RadioGroup
                  row
                  value={value}
                  onBlur={onBlur}
                  onChange={(e) => {
                    onChange(e);
                    if (onChangeProp) {
                      console.log(name);
                      console.log(parseInt(e.target.value, 10));
                      onChangeProp(name, parseInt(e.target.value, 10));
                    }
                  }}
                >
                  {radioOptions()}
                </RadioGroup>
              );
            }}
          />
        }
        label={label}
      />
      {isError && (
        <FormHelperText className={classes.error}>
          {_get(errors, name).message}
        </FormHelperText>
      )}
    </Box>
  );
}

FormRadioGroup.propTypes = {
  name: PropTypes.string,
  defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  type: PropTypes.string,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  options: PropTypes.array,
  errors: PropTypes.object,
  control: PropTypes.any,
  onChange: PropTypes.func,
  isDisabled: PropTypes.bool,
  backgroundColor: PropTypes.string,
};

FormRadioGroup.defaultProps = {
  isDisabled: false,
};

export default FormRadioGroup;
