import axios from "axios";
import { services } from "configs/api";
import { toSnakeCaseKeys } from "common/helpers/utils";
import { vpcForEnvironment } from "./environments";

const DataTransfers = {
  get(id) {
    return axios.get(services.dataTransferInternal(id));
  },
  start(payload) {
    const environment = payload['fromEnvironment'];
    if ("production" === vpcForEnvironment(environment)) {
        return axios.post(
            services.dataTransferProduction("initiate"),
            toSnakeCaseKeys(payload)
          );
      } else {
        return axios.post(
            services.dataTransferInternal("initiate"),
            toSnakeCaseKeys(payload)
          );
      }
  },
  getDatabases(environment) {
    if ("production" === vpcForEnvironment(environment)) {
      return axios.get(
        services.dataTransferProduction(`list-databases/${environment}`)
      );
    } else {
      return axios.get(
        services.dataTransferInternal(`list-databases/${environment}`)
      );
    }
  },
};

export default DataTransfers;
