import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';

import useUser from 'hooks/useUser';

function RouteGuard({
    fallBackRoute,
    mod
}) {
    const user = useUser();
    
    return (user.canUseModule(mod) ? <Outlet /> : <Navigate to={fallBackRoute} replace />);
}

export default RouteGuard;