import { call, put, delay, select, takeLeading, takeEvery, takeLatest, } from 'redux-saga/effects';

import DataTransfers from 'services/dataTransfers';
import {
  startDataTransferRequested,
  startDataTransferFailed,
  startDataTransferSucceeded,
  listDatabasesRequested,
  listDatabasesFailed,
  listDatabasesSucceeded,
  fetchDataTransferStatusRequested,
  fetchDataTransferStatusFailed,
  fetchDataTransferStatusSucceeded,
  selectIsFinished,
  selectTransferId,
} from './dataTransfersSlice';

export function* handleStartDataTransfer({ payload }) {
  try {
    const { data } = yield call(DataTransfers.start, payload);

    yield put(
      startDataTransferSucceeded(data),
    );
  } catch (error) {
    let response = error.response;
    let apiError;
    if (typeof response.data !== 'undefined'
      && response.data) {
      apiError = response.data;
    } else {
      apiError = error.message;
    }
    yield put(startDataTransferFailed(apiError));
  }
}

export function* handleListDatabases({ payload }) {
  try {
    const { data } = yield call(DataTransfers.getDatabases, payload);
    const response = {
      environment: payload,
      databases: data
    }
    yield put(
      listDatabasesSucceeded(response),
    );
  } catch (error) {
    let response = error.response;
    let apiError;
    if (response.data) {
      apiError = response.data;
    } else {
      apiError = error.message;
    }
    yield put(listDatabasesFailed(apiError));
  }
}

export function* handleFetchDataTransferStatus() {
  try {
    const isFinished = yield select(selectIsFinished);
    const transferId = yield select(selectTransferId);
    if(!isFinished && transferId.length > 0) {
      const { data } = yield call(DataTransfers.get, transferId);
      const item = data ?? null;
      if(item) {
        if(item.status === 'complete') {
          if(item.st_execution_status === 'Succeeded') {
            yield put(
              fetchDataTransferStatusSucceeded(item.status)
            );
            return;
          } else if(item.st_execution_status === 'Failed') {
            yield put(
              fetchDataTransferStatusFailed({ message: 'Data Transfer has failed! Pipeline execution Failed!', item })
            );
            return;
          }
        }
      }
    }
    yield delay(20000);

    yield put(
      fetchDataTransferStatusRequested()
    );
  } catch (error) {
    let response = error.response;
    let apiError;
    if (response.data) {
      apiError = response.data;
    } else {
      apiError = error.message;
    }
    yield put(fetchDataTransferStatusFailed(apiError));
  }
}

const watchers = [
  takeLeading(startDataTransferRequested.type, handleStartDataTransfer),
  takeEvery(listDatabasesRequested.type, handleListDatabases),
  takeLatest(fetchDataTransferStatusRequested.type, handleFetchDataTransferStatus),
];

export default watchers;
