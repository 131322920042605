import React, { Suspense } from 'react';

import { makeStyles } from '@mui/styles';
import { Grid, CircularProgress } from '@mui/material';

import { useSelector } from 'react-redux';

import Header from 'components/common/Header';
import {
    selectIsUserAuthenticated,
} from 'components/App/appSlice';

const useStyles = makeStyles({
  main: {
    minHeight: '100vh',
    padding: '40px 4% 20px 4%',
  },
  adminGuest: {
    height: '100vh',
  },
  loadingFullSizeContainer: {
    width: '100vw',
    height: '100vh',
  },
});

const Layout = ({ isLoading, children }) => {
  const styles = useStyles();
  const isAuthenticated = useSelector(selectIsUserAuthenticated);
  // const userProfile = useSelector(selectUserProfile);

  const loadingFallback = () => (
    <Grid
      container
      item
      alignItems="center"
      justifyContent="center"
      className={styles.loadingFullSizeContainer}
    >
      <CircularProgress color="primary" />
    </Grid>
  );
  const masterLayout = () => (
    <>
      <Header />
      {children}
    </>
  );

  // add columns here for landing page
  const guestLayout = () => (
    <>
      {children}
    </>
  );

  const layout = () => {
    if (isAuthenticated) {
      return masterLayout();
    }
    return guestLayout();
  };

  return (
    <>
      <Suspense fallback={<div />}>
        {isLoading ? loadingFallback() : layout()}
      </Suspense>
    </>
  );
};


export default Layout;
