import React from 'react';
import { makeStyles } from '@mui/styles';
import { services, getBaseUrl } from 'configs/api';
import GoogleSSO from './GoogleSSOIcon';
import {
    Button, Grid
} from '@mui/material';


const useStyles = makeStyles((theme) => ({
    fullpage: {
        height: '100vh'
    },
    ssoButton: {
        height: '56px',
        color: '#808080',
        background: '#ffffff',
        width: '100%'
    }
  }));

const Signin = () => {
    const classes = useStyles();


    return (
        <div className={classes.fullpage}>
            <Grid container justifyContent="center" alignItems="center" className={classes.fullpage}>
                <Grid item lg={3} xs={9}>
                    <Button
                        className={classes.ssoButton}
                        variant="contained"
                        target="_self"
                        size="large"
                        startIcon={<GoogleSSO />}
                        href={`${getBaseUrl()}${services.auth('sso')}`}
                    >
                        <strong>
                            Sign in with Google
                        </strong>
                    </Button>
                </Grid>
            </Grid>
        </div>
    );
}

export default Signin;