import axios from "axios";
import { services } from "configs/api";
import { toSnakeCaseKeys } from "common/helpers/utils";

const Sftps = {
    start(payload) {
        return axios.post(services.sftp("create-user"), toSnakeCaseKeys(payload));
    }
};

export default Sftps;
