import { createSlice } from '@reduxjs/toolkit';
import { loadState } from 'common/helpers/localStorage';

export const initialState = {
    isFetching: false,
    error: null,
    branches: loadState('branches.branches') ?? {},
    fetching: []
};

export const slice = createSlice({
    name: 'branches',
    initialState,
    reducers: {
        fetchBranchesRequested: (state) => {
            state.isFetching = true;
            state.error = null;
            state.fetching.push(true);
        },
        fetchBranchesFailed: (state, action) => {
            state.isFetching = false;
            state.fetching.pop();
            state.error = action.payload;
        },
        fetchBranchesSucceeded: (state, action) => {
            state.isFetching = false;
            state.error = null;
            state.fetching.pop();
            const { payload: item } = action;
            state.branches[item.repo] = item.branches;
        },
        resetData: (state) => {
            state.branches = {};
        }
    }
});

// Actions
export const {
    fetchBranchesRequested,
    fetchBranchesFailed,
    fetchBranchesSucceeded,
    resetData
} = slice.actions;

// Selectors
export const selectIsFetching = (state) => state.branches.isFetching;
export const selectError = (state) => state.branches.error;
export const selectRepo = (state) => state.branches.repo;
export const selectBranches = (state) => state.branches.branches;
export const selectIsLoaded = (state) => Object.keys(state.branches.branches).length > 0 && state.branches.fetching.length === 0;

export default slice.reducer;
