import React from "react";
import PropTypes from "prop-types";
import { Link as RouterLink } from "react-router-dom";
import { Typography, Link, Grid } from "@mui/material";

function NavLink({
    to,
    icon,
    color,
    text,
    target,
}) {
  return (
    <Link color={color} component={RouterLink} underline="none" to={to} target={target}>
      <Grid container justifyContent="center">
        <Grid item>
          { icon }
        </Grid>
      </Grid>
      <Typography variant="subtitle1">{text}</Typography>
    </Link>
  );
}

NavLink.propTypes = {
    to: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    icon: PropTypes.any,
    color: PropTypes.string,
    text: PropTypes.string,
    target: PropTypes.string,
};

NavLink.defaultProps = {
    color: "inherit",
    text: "Add Text",
    target: "_self"
};

export default NavLink;
