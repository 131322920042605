import { call, put, delay, select, takeLeading, takeLatest, takeEvery } from 'redux-saga/effects';

import Organizations from 'services/organizations';
import {
  fetchOrganizationRequested,
  fetchOrganizationFailed,
  fetchOrganizationSucceeded,
  fetchOrganizationsForEnvironmentRequested,
  fetchOrganizationsForEnvironmentSucceeded,
  saveOrganizationRequested,
  saveOrganizationSucceeded,
  saveOrganizationFailed,
  selectCreatingSubdomain,
} from './organizationsSlice';

export function* handleFetchOrganizationsForEnvironment({ payload }) {
  try {
    const { product, environment } = payload;
    const { data } = yield call(Organizations.all, product, environment);
    const items = data ? data : null;    
    if(items && items['orgs']) {
      yield put(
        fetchOrganizationsForEnvironmentSucceeded({
          product: product,
          environment: environment,
          organizations: items['orgs']
        })
      );
    }
  } catch (error) {
    console.log(error);
    let response = error.response;
    let apiError;
    if (response.data) {
      apiError = response.data;
    } else {
      apiError = error.message;
    }
    yield put(fetchOrganizationFailed(apiError));
  }
}

export function* handleFetchOrganization() {
  try {
    const creatingSubdomain = yield select(selectCreatingSubdomain);
    if(creatingSubdomain) {
      const { data } = yield call(Organizations.get, creatingSubdomain.environment, creatingSubdomain.subdomain);
      const item = data && data.data ? data.data:null;
      if(item && item.finished === 1) {
        yield put(
          fetchOrganizationSucceeded(item)
        );
        return;
      }
    }
    yield delay(20000);

    yield put(
      fetchOrganizationRequested()
    );
  } catch (error) {
    let response = error.response;
    let apiError;
    if (response.data) {
      apiError = response.data;
    } else {
      apiError = error.message;
    }
    yield put(fetchOrganizationFailed(apiError));
  }
}

export function* handleSaveOrganization({ payload }) {
  try {
    const { data } = yield call(Organizations.create, payload);
    yield put(
        saveOrganizationSucceeded(data),
    );
  } catch (error) {
    let response = error.response;
    let apiError;
    if (response.data) {
      apiError = response.data;
    } else {
      apiError = error.message;
    }
    yield put(saveOrganizationFailed(apiError));
  }
}

const watchers = [
  takeEvery(fetchOrganizationsForEnvironmentRequested.type, handleFetchOrganizationsForEnvironment),
  takeLatest(fetchOrganizationRequested.type, handleFetchOrganization),
  takeLeading(saveOrganizationRequested.type, handleSaveOrganization),
];

export default watchers;
