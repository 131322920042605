import React from 'react';
import { CircularProgress, Box } from '@mui/material';
import useStyles from './style';

function Progress() {
  const classes = useStyles();
  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      m={1}
      p={1}
      className={classes.fullHeight}
    >
      <Box justifyContent="center">
        <CircularProgress />
      </Box>
    </Box>
  );
}

export default Progress;
