import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { makeStyles } from "@mui/styles";
import {
  Typography,
  Grid,
  Box,
  LinearProgress,
  Alert,
  List,
  ListItem
} from "@mui/material";
import { FormInput, FormSelect, FormButton, ModalAlert } from "components";
import { selectUserProfile } from "components/App/appSlice";
import * as yup from "yup";

import {
  resetSaveState,
  selectError,
  selectSucceeded,
  selectIsReleasing,
  selectReleasingRepos,
  releaseRequested
} from "stores/functions/functionsSlice";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    overflow: "none",
    marginTop: theme.spacing(4)
  },
  container: {
    "& .MuiFormControl-root": {
      paddingRight: theme.spacing(2),
      width: "100%",
      paddingBottom: "24px"
    }
  },
  submitButton: {
    float: "right"
  },
  refreshButton: {
    paddingRight: "1rem"
  },
  error: {
    whiteSpace: "pre-line",
    "& ul li": {
      padding: "0.4rem 0"
    },
    "& .MuiAlert-message": {
      padding: "0 1rem"
    }
  }
}));

const StartRelease = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const apiErrors = useSelector(selectError);
  const succeeded = useSelector(selectSucceeded);
  const releasingRepos = useSelector(selectReleasingRepos);
  const isReleasing = useSelector(selectIsReleasing);

  const repos = Object.keys(releasingRepos).map((repo, i) => {
    return { label: repo, value: repo };
  });

  const initialFormState = {
    oldVersion: "",
    reposToRelease: []
  };

  const [submittedFormData, setSubmittedFormData] = React.useState(null);
  const [modalOpen, setModalOpen] = React.useState(false);

  React.useEffect(() => {
    return () => {
      dispatch(resetSaveState());
    };
    // eslint-disable-next-line
  }, [dispatch]);

  const buildSchema = () =>
    yup.object().shape({
      oldVersion: yup.string().required("Required").nullable(),
      reposToRelease: yup.array().required("Required").min(1)
    });

  const {
    handleSubmit,
    control,
    formState: { errors }
  } = useForm({
    defaultValues: initialFormState,
    resolver: yupResolver(buildSchema())
  });

  const handleConfirmation = (formData) => {
    setSubmittedFormData(formData);
    setModalOpen(true);
  };

  const handleStartRelease = () => {
    setModalOpen(false);
    Object.values(submittedFormData.reposToRelease).forEach((repoToRelease) => {
      dispatch(
        releaseRequested({
          oldVersion: submittedFormData.oldVersion,
          repo: repoToRelease.value
        })
      );
    });
  };

  const getProgressBanner = () => {
    if (Object.keys(apiErrors).length <= 0 && isReleasing) {
      return (
        <>
          <Alert severity="info">Release is starting...</Alert>
          <LinearProgress />
        </>
      );
    }
  };

  const getErrorBanner = () => {
    if (Object.keys(apiErrors).length > 0) {
      return (
        <Alert severity="error" className={classes.error}>
          <List sx={{ listStyleType: "disc" }}>
            {Object.values(apiErrors).map((err, i) => (
              <ListItem key={i} sx={{ display: "list-item" }}>
                {err}
              </ListItem>
            ))}
          </List>
        </Alert>
      );
    }
  };

  const getSuccessBanner = () => {
    if (succeeded && !isReleasing) {
      return (
        <Alert severity="success" mt={1}>
          <Typography variant="body2" pb={1}>
            Repository branches have been updated! The deployment pipelines
            should have started.
          </Typography>
          <Typography variant="body2">
            Please refer to the #enroll-pipeline-notifications channel in Slack
            for updates.
          </Typography>
        </Alert>
      );
    }
  };

  const renderForm = () => (
    <Grid container justifyContent="center">
      <Grid item xs={6}>
        <Box width="inherit" mt={2}>
          <form onSubmit={handleSubmit(handleConfirmation)}>
            <Grid
              container
              direction="column"
              spacing={2}
              className={classes.container}
            >
              <Grid item xs={10} lg={6}>
                <FormInput
                  name="oldVersion"
                  label="Previous Enroll Version"
                  errors={errors}
                  isDisabled={isReleasing}
                  control={control}
                  variant="filled"
                  description="The current Enroll version that is being retired by this release"
                />
              </Grid>
              <Grid item>
                <FormSelect
                  options={repos}
                  name="reposToRelease"
                  label="Select Repositories"
                  errors={errors}
                  isDisabled={isReleasing}
                  control={control}
                  isMulti={true}
                  description="Select the repositories to update"
                />
              </Grid>
              <Grid item>
                <Box>
                  <FormButton
                    type="submit"
                    variant="contained"
                    color="primary"
                    fullWidth
                    value={
                      isReleasing ? "Starting Release..." : "Start Release"
                    }
                    isSubmitting={isReleasing}
                    disableElevation
                  />
                </Box>
              </Grid>
            </Grid>
          </form>
        </Box>
      </Grid>
    </Grid>
  );

  return (
    <>
      <ModalAlert
        open={modalOpen}
        title="Start Release"
        agreeText="Release"
        disagreeText="Cancel"
        innerHTML={
          "Are you sure you want to start a release to production?<br><br>Once you confirm, the current state of the <b>STAGING</b> branch will be sent to <b>PRODUCTION</b>"
        }
        onAgree={handleStartRelease}
        onDisagree={() => setModalOpen(false)}
        onClose={() => setModalOpen(false)}
      />
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
      >
        <Grid container item justifyContent="space-between" xs={6}>
          <Grid item>
            <Box width="inherit" mt={3} mb={1}>
              <Typography variant="h4" align="center" color="primary">
                Start Enroll Release
              </Typography>
            </Box>
          </Grid>
        </Grid>
        <Grid container alignItems="center" justifyContent="center">
          <Grid item lg={6} sm={12}>
            {getErrorBanner()}
            {getSuccessBanner()}
            {getProgressBanner()}
          </Grid>
        </Grid>
        {renderForm()}
      </Grid>
    </>
  );
};

export default StartRelease;
