import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { makeStyles } from "@mui/styles";
import {
  Typography,
  Grid,
  Box,
  Tooltip,
  CircularProgress,
  LinearProgress,
  IconButton,
  Alert,
} from "@mui/material";
import RefreshIcon from "@mui/icons-material/Refresh";
import {
    FormButton,
    FormSelect
  } from "components";
import { 
  selectEnvironmentOrganizations,
  resetData as refreshOrganizationList,
  selectIsFetching as selectIsOrganizationFetching,
  selectIsLoaded as selectIsOrganizationLoaded,
  fetchOrganizationsForEnvironmentRequested
} from "stores/organizations/organizationsSlice";
import {
  fetchDataTransferStatusRequested,
  resetSaveState,
  startDataTransferRequested,
  selectError,
  selectSucceeded,
  selectIsSaving,
  selectIsFinished,
} from "stores/dataTransfers/dataTransfersSlice";

import * as yup from "yup";

import Environments from "services/environments";
import useUser from "hooks/useUser";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    overflow: "none",
    marginTop: theme.spacing(4),
  },
  container: {
    "& .MuiFormControl-root": {
      paddingRight: theme.spacing(2),
      width: "100%",
      paddingBottom: "24px",
    },
  },
  submitButton: {
    float: "right",
  },
  refreshButton: {
    paddingRight: "1rem",
  },
}));

const StartDataTransfer = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const user = useUser();
  const error = useSelector(selectError);
  const savedSucceeded = useSelector(selectSucceeded);
  const isSaving = useSelector(selectIsSaving);
  const isFinished = useSelector(selectIsFinished);
  const isOrganizationFetching = useSelector(selectIsOrganizationFetching);
  const environmentOrganizations = useSelector(selectEnvironmentOrganizations);
  const isOrganizationLoaded = useSelector(selectIsOrganizationLoaded);

  const sourceEnvironments = Object.keys(Environments).filter(
    (env) => !["ondemand"].includes(env)
  );

  const initialFormState = {
    fromEnvironment: "",
    toEnvironment: "",
    organization: "",
    deidentify: true,
  };

  const [formValues, setFormValues] = React.useState(initialFormState);

  React.useEffect(() => {
    if (savedSucceeded && !isFinished) {
      setTimeout(() => dispatch(fetchDataTransferStatusRequested()), 5000);
    }
  }, [dispatch, savedSucceeded, isFinished]);

  React.useEffect(() => {
    sourceEnvironments.forEach((environment) => {
      if (
        !environmentOrganizations.hasOwnProperty('enroll') ||
        !environmentOrganizations['enroll'].hasOwnProperty(environment) 
      ) {
        dispatch(
          fetchOrganizationsForEnvironmentRequested({
            environment,
            product: 'enroll'
          })
        );
      }
    });
    return () => {
      dispatch(resetSaveState());
    };
    // eslint-disable-next-line
  }, [dispatch, isOrganizationLoaded]);

  const buildSchema = () =>
    yup.object().shape({
      fromEnvironment: yup.object().required("Required").nullable(),
      toEnvironment: yup.object().required("Required").nullable(),
      organization: yup
        .object()
        .shape({
          id: yup.string(),
          subdomain: yup.string(),
        })
        .typeError("Required")
        .required("Required"),
    });
  const {
    handleSubmit,
    control,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(buildSchema()),
  });

  const buildEnvironmentOptions = () => {
    const options = sourceEnvironments.map((env) => ({
      value: env,
      label: env.charAt(0).toUpperCase() + env.slice(1),
    }));

    return options.sort((a, b) => -b.label.localeCompare(a.label));
  };

  const buildOrganizationOptions = () => {
    if (
      isOrganizationFetching ||
      !formValues.fromEnvironment ||
      !environmentOrganizations.hasOwnProperty('enroll') ||
      !environmentOrganizations['enroll'].hasOwnProperty(formValues.fromEnvironment)
    ) {
      return [];
    }

    const options = environmentOrganizations['enroll'][formValues.fromEnvironment].map(
      (org) => ({
        value: org.id,
        label: `${org.id} - ${org.organization_name} (${org.subdomain})`,
        subdomain: org.subdomain,
        organization_name: org.organization_name,
      })
    );

    return options;
  };

  const handleFieldChange = (input, newValue) => {
    let updatedFormValues = {
      ...formValues,
      [input]: newValue,
    };
    if (input === "fromEnvironment") {
      updatedFormValues = {
        ...formValues,
        [input]: newValue
      };
      setValue('organization', null);
    }

    setFormValues(updatedFormValues);
  };

  const handleOrganizationChange = (selectedValue) => {
    handleFieldChange("organization", {
      id: selectedValue.value,
      subdomain: selectedValue.subdomain,
    });
    handleFieldChange('organizationValue', selectedValue);
  };

  const handleStartDataTransfer = (formData) => {
    let payload = {
      transferType: 'org',
      trigger: 'manual',
      product: 'enroll',
      email: user.email
    };
    Object.keys(formData).forEach((inputKey) => {
      if (inputKey === "deidentify") {
        payload[inputKey] = Number(formData[inputKey]);
      } else if (inputKey === "organization") {
        payload["orgId"] = formData[inputKey].value.toString();
        payload["orgSubdomain"] = formData[inputKey].subdomain;
      } else {
        payload[inputKey] = formData[inputKey].value;
      }
    });
    dispatch(startDataTransferRequested(payload));
  };

  const handleRefreshData = () => {
    dispatch(refreshOrganizationList());
  };

  const getErrorBanner = () => {
    if (error) {
      return (
        <Alert severity="error">
          <pre>{JSON.stringify(error, null, 2)}</pre>
        </Alert>
      );
    }
  };

  const getSuccessBanner = () => {
    if (savedSucceeded && isFinished) {
      return <Alert severity="success">Data Transfer complete!</Alert>;
    }
  };

  const getProgressBanner = () => {
    if (!isFinished) {
      return (
        <>
          <Alert severity="info">
            Data transfer in progress. This process could take a while.
          </Alert>
          <LinearProgress />
        </>
      );
    }
  };

  const renderForm = () => (
    <Grid container justifyContent="center">
        <Grid item xs={6}>
          <Box width="inherit" mt={2}>
            <form onSubmit={handleSubmit(handleStartDataTransfer)}>
              <Grid container direction="column" spacing={2}>
              <Grid item>
                <FormSelect
                  options={buildEnvironmentOptions().filter((e) => e.value !== 'performance')}
                  name="fromEnvironment"
                  label="Source Environment"
                  errors={errors}
                  isDisabled={isSaving || !isFinished}
                  onChange={handleFieldChange}
                  control={control}
                  description="Environment to get the data from"
                />
              </Grid>
              <Grid item>
                <FormSelect
                  options={buildEnvironmentOptions().filter((e) => e.value !== 'production')}
                  name="toEnvironment"
                  label="Destination Environment"
                  errors={errors}
                  isDisabled={isSaving || !isFinished}
                  onChange={handleFieldChange}
                  control={control}
                  description="Environment to send the data to"
                />
              </Grid>
              <Grid item>
                <FormSelect
                  options={buildOrganizationOptions()}
                  name="organization"
                  label="Organization"
                  errors={errors}
                  isDisabled={isSaving || !isFinished}
                  onChange={handleOrganizationChange}
                  control={control}
                  description="Organization to extract from"
                />
              </Grid>
                <Grid item>
                  <Box>
                    <FormButton
                      type="submit"
                      variant="contained"
                      color="primary"
                      fullWidth
                      value={isFinished ? "Start Transfer" : "Transferring Data..."}
                      isSubmitting={isSaving || !isFinished}
                      disableElevation
                    />
                  </Box>
                </Grid>
              </Grid>
            </form>
          </Box>
        </Grid>
      </Grid>
  );

  return (
    <Grid container direction="row" justifyContent="center" alignItems="center">
      <Grid container item justifyContent="space-between" xs={6}>
        <Grid item>
          <Box width="inherit" mt={3}>
            <Typography variant="h4" align="center" color="primary">
              Start Data Transfer
            </Typography>
          </Box>
        </Grid>
        <Grid item>
          <Box width="inherit" mt={2}>
            <Tooltip title="Refresh Lists">
              <IconButton color="primary" onClick={handleRefreshData} size="large">
                <RefreshIcon />
              </IconButton>
            </Tooltip>
          </Box>
        </Grid>
      </Grid>

      <Grid container alignItems="center" justifyContent="center">
        <Grid item lg={6} sm={12}>
          {getErrorBanner()}
          {getSuccessBanner()}
          {getProgressBanner()}
        </Grid>
      </Grid>

      {isOrganizationLoaded ? (
        renderForm()
      ) : (
        <Grid
          container
          justify-xs-center="true"
          direction="row"
          className={classes.container}
        >
          <Grid
            container
            alignItems="center"
            justifyContent="center"
            style={{ minHeight: "100vh" }}
          >
            <Grid item>
              <CircularProgress />
            </Grid>
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};

export default StartDataTransfer;
