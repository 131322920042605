import React, { useRef } from "react";
import { Controller } from "react-hook-form";
import PropTypes from "prop-types";
import { get as _get, isEqual } from "lodash";
import { Box, FormHelperText, TextField } from "@mui/material";

import Autocomplete from "@mui/material/Autocomplete";
import { Progress } from "components";
import useStyles from "./style";

function FormSelect({
  defaultValue,
  description,
  name,
  label,
  options,
  control,
  groupBy,
  errors,
  isDisabled,
  isMulti,
  isAutoComplete,
  onChange: onChangeProp,
  isLoading,
  size,
  background,
  getOptionLabel,
  disableClearable,
  getOptionDisabled,
  startAdornment,
  freeSolo
}) {
  const classes = useStyles();
  const inputRef = useRef(null);
  const isError = _get(errors, name) !== undefined;
  const descriptionId = `${name}_description`;

  return (
    <Box mt={1} mb={1}>
      <Controller
        control={control}
        name={name}
        defaultValue={defaultValue || null}
        onFocus={() => {
          inputRef.current.focus();
        }}
        render={({ field }) => (
          <Autocomplete
            disabled={isDisabled}
            disableClearable={disableClearable}
            className={
              background !== "none" && classes[background]
                ? classes[background]
                : ""
            }
            size={size}
            value={field.value}
            options={options}
            groupBy={groupBy}
            filterSelectedOptions
            getOptionLabel={getOptionLabel}
            isOptionEqualToValue={(option, value) => isEqual(option, value)}
            freeSolo={freeSolo}
            renderInput={(params) => {
              if (isLoading) {
                return <Progress />;
              }
              let inputProps = {
                ...params.InputProps
              };
              if (startAdornment) {
                inputProps["startAdornment"] = startAdornment;
              }

              return (
                <TextField
                  {...params}
                  error={isError}
                  InputProps={inputProps}
                  name={name}
                  inputRef={inputRef}
                  label={label}
                  disabled={isDisabled}
                  variant="standard"
                />
              );
            }}
            onChange={(_, data) => {
              field.onChange(data);
              if (onChangeProp) {
                if (isMulti) {
                  if (Array.isArray(data)) {
                    onChangeProp(
                      name,
                      data.map((option) => option.value)
                    );
                  } else {
                    // form clear option
                    onChangeProp(name, []);
                  }
                } else {
                  // sigle option dropdown
                  if (data && !Array.isArray(data)) {
                    onChangeProp(name, data.value);
                  } else {
                    // form clear option
                    onChangeProp(name, "");
                  }
                }
              }
            }}
            multiple={isMulti}
            getOptionDisabled={getOptionDisabled}
          />
        )}
      />
      <FormHelperText id={descriptionId}>{description}</FormHelperText>
      {isError && (
        <FormHelperText className={classes.error}>
          {_get(errors, name).message}
        </FormHelperText>
      )}
    </Box>
  );
}

FormSelect.propTypes = {
  name: PropTypes.string,
  description: PropTypes.string,
  label: PropTypes.string,
  options: PropTypes.array,
  errors: PropTypes.object,
  control: PropTypes.any,
  defaultValue: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
    PropTypes.array
  ]),
  isDisabled: PropTypes.bool,
  isMulti: PropTypes.bool,
  isAutoComplete: PropTypes.bool,
  isLoading: PropTypes.bool,
  onChange: PropTypes.func,
  size: PropTypes.string,
  background: PropTypes.string,
  disableClearable: PropTypes.bool,
  groupBy: PropTypes.func,
  getOptionLabel: PropTypes.func,
  getOptionDisabled: PropTypes.func,
  startAdornment: PropTypes.element,
  freeSolo: PropTypes.bool,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
    PropTypes.array
  ])
};

FormSelect.defaultProps = {
  description: "",
  freeSolo: false,
  isLoading: false,
  isMulti: false,
  isDisabled: false,
  isAutoComplete: true,
  size: "medium",
  background: "none",
  onChange: () => {},
  disableClearable: false,
  groupBy: () => {},
  getOptionLabel: (option) =>
    option && option.label ? option.label.toString() : "",
  getOptionDisabled: () => {}
};

export default FormSelect;
