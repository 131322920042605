import { useSelector } from 'react-redux';
import { selectUserProfile, selectIsUserAuthenticated } from 'components/App/appSlice';

// this logic only works with unique action name
const checkPermissions = (permissions, mod, action) => {
  return permissions[`${mod}:${action}`];
};

const checkModuleAccess = (permissions, mod) => {
  return permissions.filter((perm) => perm.includes(mod)).length > 0;
};

function useUser() {
  const authenticated = useSelector(selectIsUserAuthenticated);
  const user = useSelector(selectUserProfile);
  if (authenticated) {
    const { permissions } = user;

    return {
      ...user,
      canUseModule: (mod) => checkModuleAccess(permissions, mod),
      can: (mod, action) => checkPermissions(permissions, mod, action),
    };
  }
  return {
    ...user,
    canUseModule: () => false,
    can: () => false,
  };
}

export default useUser;
