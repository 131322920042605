import axios from 'axios';
import { services } from 'configs/api';


const Authentication = {
  userProfile() {
    return axios.get(services.auth('sso/user'));
  },
  logout() {
    return axios.get(services.auth('sso/logout'))
  }
};

export default Authentication;
