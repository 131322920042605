import axios from 'axios';
import { services } from 'configs/api';

const Branches = {
    get(repo) {
        return axios.get(services.ondemand(`branches/${repo}`));
    },
};

export default Branches;
