const Environments = {
    'development': 'https://subdomain.dev.schoolmint.com',
    'testing': 'https://subdomain.test.schoolmint.com',
    'staging': 'https://subdomain.staging.schoolmint.com',
    'sandbox': 'https://subdomain.sandbox.schoolmint.com',
    'production': 'https://subdomain.schoolmint.com',
    'beta': 'https://subdomain.beta.schoolmint.com',
    'performance': 'https://subdomain.performance.schoolmint.com',
    'ondemand': 'https://subdomain.demand.schoolmint.com',
};

export const vpcForEnvironment = (env) => {
    if (['production', 'sandbox', 'beta'].includes(env)) {
        return 'production';
    } else {
        return 'internal';
    }
}

export default Environments;
