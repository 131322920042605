import { createSlice } from '@reduxjs/toolkit';
import { loadState } from 'common/helpers/localStorage';

export const initialState = {
    isSaving: false,
    isFetching: false,
    isFinishedCreating: true,
    environmentOrganizations: loadState('organizations.environmentOrganizations') ?? {},
    fetching: [],
    organizations: {},
    error: null,
    succeeded: false,
    creatingSubdomain: null,
};

export const slice = createSlice({
  name: 'organizations',
  initialState,
  reducers: {
    fetchOrganizationRequested: (state) => {
      state.isFetching = true;
      state.error = null;
    },
    fetchOrganizationFailed: (state, action) => {
      state.isFetching = false;
      state.error = action.payload;
      state.isFinishedCreating = true;
      state.fetching.pop();
    },
    fetchOrganizationSucceeded: (state, action) => {
      state.isFetching = false;
      state.error = null;
      state.isFinishedCreating = true;
      const { payload: item } = action;
      state.organizations[item.subdomain] = item;
    },
    fetchOrganizationsForEnvironmentRequested: (state) => {
      state.isFetching = true;
      state.error = null;
      state.fetching.push(true);
    },
    fetchOrganizationsForEnvironmentSucceeded: (state, action) => {
      state.isFetching = false;
      state.error = null;
      state.fetching.pop();
      const { product, environment, organizations } = action.payload;
      if(!state.environmentOrganizations.hasOwnProperty(product)) {
        state.environmentOrganizations[product] = {}
      }
      state.environmentOrganizations[product][environment] = organizations;
    },
    saveOrganizationRequested: (state, action) => {
      state.isSaving = true;
      state.error = null;
      state.succeeded = false;
      state.creatingSubdomain = {
        'subdomain': action.payload.subdomain,
        'environment': action.payload.environment
      };
    },
    saveOrganizationFailed: (state, action) => {
      state.isSaving = false;
      state.error = action.payload;
      state.succeeded = false;
      state.isFinishedCreating = true;      
    },
    saveOrganizationSucceeded: (state, action) => {
      state.isSaving = false;
      state.error = null;
      state.succeeded = true;
      state.isFinishedCreating = false;
      const item = action.payload;
      state.organizations[item.subdomain] = item;
    },
    resetSaveState: (state) => {
      state.error = null;
      state.isSaving = false;
      state.succeeded = false;
    },
    resetData: (state) => {
      state.environmentOrganizations = {}
    }
  }
});

// Actions
export const {
  fetchOrganizationRequested,
  fetchOrganizationFailed,
  fetchOrganizationSucceeded,
  fetchOrganizationsForEnvironmentRequested,
  fetchOrganizationsForEnvironmentSucceeded,
  saveOrganizationRequested,
  saveOrganizationFailed,
  saveOrganizationSucceeded,
  resetSaveState,
  resetData
} = slice.actions;

// Selectors
export const selectIsSaving = (state) => state.organizations.isSaving;
export const selectSucceeded = (state) => state.organizations.succeeded;
export const selectError = (state) => state.organizations.error;
export const selectIsFetching = (state) => state.organizations.isFetching;
export const selectOrganizations = (state) => state.organizations.organizations;
export const selectIsFinishedCreating = (state) => state.organizations.isFinishedCreating;
export const selectCreatingSubdomain = (state) => state.organizations.creatingSubdomain;
export const selectEnvironmentOrganizations = (state) => state.organizations.environmentOrganizations;
export const selectIsLoaded = (state) => Object.keys(state.organizations.environmentOrganizations).length > 0 && state.organizations.fetching.length === 0;

export default slice.reducer;
