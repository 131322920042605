import React from 'react';
import PropTypes from 'prop-types';
import { Controller } from 'react-hook-form';
import { Box, Checkbox, FormControlLabel, Typography } from '@mui/material';
import { get as _get } from 'lodash';
import useStyles from './style';

function FormCheckbox({
  name,
  label,
  control,
  errors,
  defaultChecked,
  onChange: onChangeProp,
  isDisabled,
  backgroundColor,
}) {
  const classes = useStyles();
  const isError = _get(errors, name) && _get(errors, name).message;
  return (
    <Box>
      <FormControlLabel
        control={
          <Controller
            name={name}
            control={control}
            defaultValue={defaultChecked}
            render={({ field, ...defaultProps }) => {
              if (!backgroundColor) {
                defaultProps['color'] = 'primary';
              }
              return (
                <Checkbox
                  style={{
                    color: backgroundColor,
                  }}
                  checked={defaultChecked}
                  onChange={(e) => {
                    field.onChange(e.target.checked);
                    if (onChangeProp) {
                      onChangeProp(name, e.target.checked);
                    }
                  }}
                />
              );
            }}
          />
        }
        label={label}
      />
      {isError && (
        <Typography
          className={classes.error}
          variant="caption"
          display="block"
          gutterBottom
        >
          {_get(errors, name).message}
        </Typography>
      )}
    </Box>
  );
}

FormCheckbox.propTypes = {
  name: PropTypes.string,
  type: PropTypes.string,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  errors: PropTypes.object,
  control: PropTypes.any,
  defaultChecked: PropTypes.bool,
  onChange: PropTypes.func,
  isDisabled: PropTypes.bool,
  backgroundColor: PropTypes.string,
};

FormCheckbox.defaultProps = {
  isDisabled: false,
};

export default FormCheckbox;
