import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
  isReleasing: false,
  errors: {},
  succeeded: false,
  releasingRepos: {
    "enrollment-be": false,
    "enrollment-fe": false,
    "enrollment-be-custom": false,
    "enrollment-fe-custom": false,
    "sis-service": false,
    "sis-service-fe": false
  }
};

export const slice = createSlice({
  name: "functions",
  initialState,
  reducers: {
    releaseRequested: (state, { payload }) => {
      state.releasingRepos[payload["repo"]] = true;
      state.isReleasing = true;
      delete state.errors[payload["repo"]];
      state.succeeded = false;
    },
    releaseFailed: (state, { payload }) => {
      state.isReleasing = false;
      state.errors[
        payload["repo"]
      ] = `Error in Releasing ${payload["repo"]}: ${payload["error"]}`;
      state.succeeded = false;
      state.releasingRepos[payload["repo"]] = false;
    },
    releaseSucceeded: (state, { payload }) => {
      state.releasingRepos[payload] = false;
      state.isReleasing = Object.values(state.releasingRepos).some(
        (e) => e === true
      );
      delete state.errors[payload["repo"]];
      state.succeeded = !state.isReleasing;
    },
    resetSaveState: (state) => {
      state.errors = {};
      state.isReleasing = false;
      state.succeeded = false;
      state.releasingRepos = {
        "enrollment-be": false,
        "enrollment-fe": false,
        "enrollment-be-custom": false,
        "enrollment-fe-custom": false,
        "sis-service": false,
        "sis-service-fe": false
      };
    }
  }
});

// Actions
export const {
  releaseRequested,
  releaseFailed,
  releaseSucceeded,
  resetSaveState
} = slice.actions;

// Selectors
export const selectSucceeded = (state) => state.functions.succeeded;
export const selectError = (state) => state.functions.errors;
export const selectIsReleasing = (state) => state.functions.isReleasing;
export const selectReleasingRepos = (state) => state.functions.releasingRepos;

export default slice.reducer;
