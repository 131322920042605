import axios from 'axios';
import { services } from 'configs/api';
import { toSnakeCaseKeys } from 'common/helpers/utils';

const Churn = {
    schedule(payload) {
        return axios.post(services.churn('schedule'), toSnakeCaseKeys(payload));
    }
};

export default Churn;