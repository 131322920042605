import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
    isScheduling: false,
    isFetching: false,
    isFinished: true,
    error: null,
    succeeded: false,
    churnRecord: {}
};

export const slice = createSlice({
  name: 'churn',
  initialState,
  reducers: {
    scheduleChurnRequested: (state) => {
      state.isScheduling = true;
      state.error = null;
      state.succeeded = false;
      state.isFinished = false;
    },
    scheduleChurnFailed: (state, action) => {
      state.isScheduling = false;
      state.error = action.payload;
      state.succeeded = false;
      state.isFinished = true;
    },
    scheduleChurnSucceeded: (state, action) => {
      state.isScheduling = false;
      state.error = null;
      state.succeeded = true;
      state.isFinished = true;
      state.churnRecord = action.payload['Item'];
    },
    resetSaveState: (state) => {
      state.error = null;
      state.isScheduling = false;
      state.succeeded = false;
    }
  }
});

// Actions
export const {
  scheduleChurnRequested,
  scheduleChurnFailed,
  scheduleChurnSucceeded,
  resetSaveState
} = slice.actions;

// Selectors
export const selectSucceeded = (state) => state.churn.succeeded;
export const selectError = (state) => state.churn.error;
export const selectIsFinished = (state) => state.churn.isFinished;
export const selectIsScheduling = (state) => state.churn.isScheduling;
export const selectChurnRecord = (state) => state.churn.churnRecord;

export default slice.reducer;
