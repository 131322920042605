import React, { FC, memo } from "react";
import PropTypes from "prop-types";
import { Controller } from "react-hook-form";
import { get as _get } from "lodash";
import { isNull } from "lodash";
import useStyles from "./style";

import { Box, TextField, Typography } from "@mui/material";

function FormInput({
  identifier,
  name,
  control,
  errors,
  onChange: changedFn,
  defaultValue,
  isDisabled,
  whiteBackground,
  ...inputProps
}) {
  const classes = useStyles();
  const isError = _get(errors, name) && _get(errors, name).message;

  return (
    <Box>
      <Controller
        control={control}
        name={name}
        defaultValue={!isNull(defaultValue) ? defaultValue : ""}
        render={({ field }) => (
          <TextField
            id={`forminput-` + (identifier ? identifier : name)}
            error={_get(errors, name) ? true : false}
            onChange={(event) => {
              changedFn(name, event.target.value);
            }}
            disabled={isDisabled}
            className={
              whiteBackground ? classes.whiteBackground : classes.marginTop
            }
            {...field}
            {...inputProps}
          />
        )}
      />
      {isError && (
        <Typography
          className={classes.error}
          variant="caption"
          display="block"
          gutterBottom
        >
          {_get(errors, name).message}
        </Typography>
      )}
    </Box>
  );
}

FormInput.propTypes = {
  identifier: PropTypes.string,
  name: PropTypes.string,
  type: PropTypes.string,
  label: PropTypes.string,
  errors: PropTypes.object,
  control: PropTypes.any,
  defaultValue: PropTypes.string,
  onChange: PropTypes.func,
  isDisabled: PropTypes.bool,
  whiteBackground: PropTypes.bool,
  helperText: PropTypes.string
};

FormInput.defaultProps = {
  isDisabled: false,
  helperText: "",
  whiteBackground: false
};

export default FormInput;
