import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { makeStyles } from "@mui/styles";
import {
  Typography,
  Grid,
  Box,
  LinearProgress,
  IconButton,
  Tooltip,
  CircularProgress,
  Alert,
  AlertTitle,
} from "@mui/material";

import RefreshIcon from "@mui/icons-material/Refresh";
import { FormSelect, FormButton } from "components";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

import {
  fetchOrganizationsForEnvironmentRequested,
  selectEnvironmentOrganizations,
  resetData as refreshOrganizationList,
  selectIsLoaded as selectIsOrganizationLoaded,
} from "stores/organizations/organizationsSlice";

import {
  createSftpRequested,
  selectIsSaving,
  selectError,
  selectSftp,
  isFinishedCreating,
} from "stores/sftp/sftpSlice"

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    overflow: "none",
    marginTop: theme.spacing(4),
  },
  container: {
    "& .MuiFormControl-root": {
      paddingRight: theme.spacing(2),
      width: "100%",
      paddingBottom: "24px",
    },
  },
  submitButton: {
    float: "right",
  },
  refreshButton: {
    paddingRight: "1rem",
  },
  alert: {
    marginBottom: "1rem"
  }
}));



const SftpAccount = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const sftpError = useSelector(selectError);
  const sftpData = useSelector(selectSftp);
  const isSaving = useSelector(selectIsSaving);
  const environmentOrganizations = useSelector(selectEnvironmentOrganizations);
  const isOrganizationLoaded = useSelector(selectIsOrganizationLoaded);

  const initialFormState = {
    product: 'enroll',
    fromEnvironment: 'production',
    organization: null,
  };

  const [formValues, setFormValues] = React.useState(initialFormState);

  React.useEffect(() => {
    if (!isOrganizationLoaded) {
      dispatch(
        fetchOrganizationsForEnvironmentRequested({ product: "enroll", environment: "production" })
      );
    }
    // eslint-disable-next-line
  }, [dispatch, isOrganizationLoaded]);

  const getErrorBanner = (sftpError = '') => {
    if (sftpError) {
      return (
        <Alert severity="error">
          <pre>{JSON.stringify(sftpError, null, 2)}</pre>
        </Alert>
      );
    }
  };
  
  
  const getSuccessBanner = (isFinishedCreating = false, sftpData = '') => {
    if (isFinishedCreating && sftpData) {
      return (
        <>
        <Alert severity="success" className={classes.alert}>
          <AlertTitle>
          SFTP Account Created Successfully
          </AlertTitle>
          <Typography variant="body1">
          {sftpData}
          </Typography>     
        </Alert>
        <Alert severity="warning" >
        <AlertTitle>Please save these credentials in a secure location immediately! </AlertTitle>
            The password can only be retrieved when the account is created.
        </Alert>
        </>
      );
    }
  };
  
  const getProgressBanner = (isSaving = false) => {
    if (isSaving) {
      return (
        <>
          <Alert severity="info">SFTP Account is being created...</Alert>
          <LinearProgress />
        </>
      );
    }
  };
  
  const buildOrganizationOptions = (environmentOrganizations = {}, product = '', environment = '') => {
    const organizations = environmentOrganizations[product];
  
    if (!organizations) {
      return [];
    }
  
    const options = organizations[environment].map((org) => {
      return {
        value: org.id,
        label: `${org.id} - ${org.organization_name} (${org.subdomain})`,
        subdomain: org.subdomain,
        organization_name: org.organization_name,
      }
    });
  
    return options;
  };
  
  const buildSchema = () =>
    yup.object().shape({
      organization: yup
        .object()
        .shape({
          id: yup.number(),
          subdomain: yup.string(),
        })
        .typeError("Required")
        .required("Required"),
    });

  const { handleSubmit, control, formState: { errors } } = useForm({
    defaultValues: initialFormState,
    resolver: yupResolver(buildSchema()),
  });

  const handleFieldChange = (input, newValue) => {
    const updatedformValues = {
      ...formValues,
      [input]: newValue,
    };
    setFormValues(updatedformValues);
  };

  const handleOrganizationChange = (selectedValue) => {
    handleFieldChange("organization", {
      id: selectedValue.value,
      subdomain: selectedValue.subdomain,
    });
  };

  const handleRefreshData = () => {
    // dispatch(refreshBranchList());
    dispatch(refreshOrganizationList());
  };

  const handleCreateSftp = (formData) => {
    const payload = {
      // for testing purposes we can fake org here, like:
      // username: 'fakedomainhere'
      username: formData["organization"].subdomain
    };

    dispatch(createSftpRequested(payload));
  };

  const renderForm = () => (
    <>
      <Grid item>
        <FormSelect
          options={buildOrganizationOptions(environmentOrganizations, formValues.product, formValues.fromEnvironment)}
          name="organization"
          label="Organization"
          errors={errors}
          isDisabled={isSaving || !isFinishedCreating}
          onChange={handleOrganizationChange}
          control={control}
          description="Organization"
        />
      </Grid>
      <Grid item>
        <Box>
          <FormButton
            type="submit"
            variant="contained"
            color="primary"
            fullWidth
            value={isFinishedCreating ? "Create" : "Creating..."}
            isSubmitting={isSaving}
            disableElevation
          />
        </Box>
      </Grid>
    </>
  );

  return (
    <Grid container direction="row" justifyContent="center" alignItems="center">
      <Grid container item justifyContent="space-between" xs={6}>
        <Grid item>
          <Box width="inherit" mt={3}>
            <Typography variant="h4" align="center" color="primary">
              Create SFTP Account
            </Typography>
          </Box>
        </Grid>
        <Grid item>
          <Box width="inherit" mt={2}>
            <Tooltip title="Refresh Lists">
              <IconButton
                color="primary"
                onClick={handleRefreshData}
                size="large"
              >
                <RefreshIcon />
              </IconButton>
            </Tooltip>
          </Box>
        </Grid>
      </Grid>

      <Grid container alignItems="center" justifyContent="center">
        <Grid item lg={6} sm={12}>
          {getErrorBanner(sftpError)}
          {getSuccessBanner(isFinishedCreating, sftpData)}
          {getProgressBanner(isSaving)}
        </Grid>
      </Grid>
      <Grid container justifyContent="center">
        <Grid item xs={6}>
          <Box width="inherit" mt={2}>
            <form onSubmit={handleSubmit(handleCreateSftp)}>
              <Grid container direction="column" spacing={2}>

                {isOrganizationLoaded ? (
                  renderForm()
                ) : (
                  <Grid
                    container
                    justify-xs-center="true"
                    direction="row"
                    className={classes.container}
                  >
                    <Grid
                      container
                      alignItems="center"
                      justifyContent="center"
                      style={{ minHeight: "100vh" }}
                    >
                      <Grid item>
                        <CircularProgress />
                      </Grid>
                    </Grid>
                  </Grid>
                )}
              </Grid>
            </form>
          </Box>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default SftpAccount;
