import { call, put, takeEvery } from "redux-saga/effects";

import Branches from "services/branches";
import {
  fetchBranchesRequested,
  fetchBranchesFailed,
  fetchBranchesSucceeded,
  selectRepo,
} from "./branchesSlice";

export function* handleFetchBranches(action) {
  const repo = action.payload;
  if (selectRepo) {
    try {
      const { data } = yield call(Branches.get, repo);
      const items = data && data.length > 0 ? data : null;

      if (items) {
        const payload = {
          repo: repo,
          branches: [],
        };

        items.map(({ name }) => payload.branches.push(name));

        yield put(fetchBranchesSucceeded(payload));
      }
    } catch (error) {
      let response = error.response;
      let apiError;
      if (response.data) {
        apiError = response.data;
      } else {
        apiError = error.message;
      }
      yield put(fetchBranchesFailed(apiError));
    }
  }
}

const watchers = [takeEvery(fetchBranchesRequested.type, handleFetchBranches)];

export default watchers;
