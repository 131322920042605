import axios from "axios";
import Authentication from "services/authentication";

export const getBaseUrl = () => {
  //   // check if the be_endpoint cookie is set
  //   // allow the FE to connect to an given endpoint
  //   if (Cookies.get('be_endpoint')) {
  //     return Cookies.get('be_endpoint');
  //   }
  let port = window.location.port;
  let apiPrefix = "api.";
  if (window.location.port === "3000") {
    port = "80";
    apiPrefix = "";
  }
  return `${window.location.protocol}//${apiPrefix}${window.location.hostname}:${port}`;
};

export const loadApiConfig = () => {
  // Axios default values
  axios.defaults.baseURL = getBaseUrl();
  axios.defaults.withCredentials = true;
  axios.defaults.headers.post["Content-Type"] = "application/json";
};

export const unauthorizeInterceptor = () => {
  axios.interceptors.response.use({}, (error) => {
    const errorResponse = error.response;
    console.log(errorResponse);

    if (!errorResponse) {
      // error.errorMessage = error;
      return Promise.reject(error);
    }

    if (errorResponse.status === 401) {
      Authentication.logout();
      return (window.location.href = `${getBaseUrl()}${services.auth(
        "sso/logout"
      )}`);
    }
    if (errorResponse.data.errors) {
      if (typeof errorResponse.data.errors === "object") {
        // TODO: make this nicer so we can show the error alert.
        error.errorMessage = JSON.stringify(errorResponse.data.errors);
      } else {
        error.errorMessage = errorResponse.data.errors;
      }
    }

    if (errorResponse.data) {
      if (typeof errorResponse.data === "object") {
        if (errorResponse.data.message) {
          error.errorMessage = errorResponse.data.message;
        }
      } else {
        error.errorMessage = errorResponse.data;
      }
    }

    return Promise.reject(error);
  });
};

export const services = {
  auth: (url) => `/platform/auth/${url}`,
  churn: (url) =>
    `/platform/api/${process.env.REACT_APP_ENVIRONMENT}/churn/${url}`,
  enroll: (env, url) => `/platform/api/enroll/${env}/${url}`,
  dataTransferInternal: (url) =>
    `/platform/api/${process.env.REACT_APP_ENVIRONMENT}/data-transfer-internal/${url}`,
  dataTransferProduction: (url) =>
    `/platform/api/${process.env.REACT_APP_ENVIRONMENT}/data-transfer-production/${url}`,
  ondemand: (url) =>
    `/platform/api/${process.env.REACT_APP_ENVIRONMENT}/ondemand/${url}`,
  sftp: (url) =>
    `/platform/api/${process.env.REACT_APP_ENVIRONMENT}/sftp-api/${url}`,
  functions: (url) =>
    `/platform/api/${process.env.REACT_APP_ENVIRONMENT}/functions/${url}`
};
