import { createSlice } from '@reduxjs/toolkit';


export const initialState = {
  isBooting: false,
  isUserAuthenticated: false,
  userProfile: {}
};

export const slice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    startBoot: (state) => {
        state.isBooting = true;
    },
    completeBoot: (state, action) => {
        state.isBooting = false;
    },
    setUserProfile: (state, action) => {
      const data = action.payload;
      if (data.email) {
        state.userProfile = action.payload;
        state.isUserAuthenticated = true
      }
    }
  }
});

export const {
  startBoot,
  completeBoot,
  setUserProfile
} = slice.actions;

export const selectIsUserAuthenticated = (state) => state.app.isUserAuthenticated;
export const selectUserProfile = (state) => state.app.userProfile;
export const selectisBooting = (state) => state.app.isBooting;

export default slice.reducer;