import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
    isFetching: false,
    error: null,
    sftpData: ""
};

export const slice = createSlice({
    name: 'sftp',
    initialState,
    reducers: {
        createSftpRequested: (state) => {
            state.isFetching = true;
            state.error = null
        },
        fetchSftpRequested: (state) => {
            state.isFetching = true;
            state.error = null;
        },
        startSftpFailed: (state, action) => {
            state.isFetching = false;
            state.error = action?.payload?.Message;
        },
        startSftpSucceeded: (state, action) => {
            state.isFetching = false;
            state.error = null;
            state.sftpData = action?.payload?.Message;
        },
        resetData: (state) => {
            state.sftpData = {};
        }
    }
});

// Actions
export const {
    createSftpRequested,
    fetchSftpRequested,
    startSftpFailed,
    startSftpSucceeded,
    resetData
} = slice.actions;

// Selectors
export const selectIsFetching = (state) => state.sftp.isFetching;
export const selectError = (state) => state.sftp.error;
export const selectSftp = (state) => state.sftp.sftpData;
export const isFinishedCreating = (state) => state.sftp.isFetching;
export const selectIsSaving = (state) => state.sftp.isFetching;


export default slice.reducer;
