import {
  call,
  put,
  takeLeading,
} from "redux-saga/effects";

import Churn from "services/churn";
import {
    scheduleChurnRequested,
    scheduleChurnFailed,
    scheduleChurnSucceeded,
} from "./churnSlice";

export function* handleScheduleChurn({ payload }) {
  try {
    const { data } = yield call(Churn.schedule, payload);

    yield put(scheduleChurnSucceeded(data));
  } catch (error) {
    let response = error.response;
    let apiError;
    if (typeof response.data !== "undefined" && response.data) {
      apiError = response.data;
    } else {
      apiError = error.message;
    }
    yield put(scheduleChurnFailed(apiError));
  }
}

const watchers = [
  takeLeading(scheduleChurnRequested.type, handleScheduleChurn),
];

export default watchers;
