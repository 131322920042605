import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { makeStyles } from "@mui/styles";
import {
  Typography,
  Grid,
  Box,
  Tooltip,
  CircularProgress,
  IconButton,
  Alert,
} from "@mui/material";
import RefreshIcon from "@mui/icons-material/Refresh";
import { FormButton, FormSelect, FormDatePicker, ModalAlert } from "components";
import { selectUserProfile } from "components/App/appSlice";
import {
  selectEnvironmentOrganizations,
  resetData as refreshOrganizationList,
  selectIsFetching as selectIsOrganizationFetching,
  selectIsLoaded as selectIsOrganizationLoaded,
  fetchOrganizationsForEnvironmentRequested,
} from "stores/organizations/organizationsSlice";

import {
  resetSaveState,
  selectError,
  selectSucceeded,
  selectIsScheduling,
  selectIsFinished,
  scheduleChurnRequested,
  selectChurnRecord,
} from "stores/churn/churnSlice";

import { addDays, format as DateFormat } from "date-fns";
import * as yup from "yup";
import { DATE_TIME_FORMATS } from "common/constants";
import Environments from "services/environments";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    overflow: "none",
    marginTop: theme.spacing(4),
  },
  container: {
    "& .MuiFormControl-root": {
      paddingRight: theme.spacing(2),
      width: "100%",
      paddingBottom: "24px",
    },
  },
  submitButton: {
    float: "right",
  },
  refreshButton: {
    paddingRight: "1rem",
  },
}));

const ChurnOrganization = () => {
  const userProfile = useSelector(selectUserProfile);
  const classes = useStyles();
  const dispatch = useDispatch();
  const error = useSelector(selectError);
  const savedSucceeded = useSelector(selectSucceeded);
  const isScheduling = useSelector(selectIsScheduling);
  const isFinished = useSelector(selectIsFinished);
  const isOrganizationFetching = useSelector(selectIsOrganizationFetching);
  const isOrganizationLoaded = useSelector(selectIsOrganizationLoaded);
  const environmentOrganizations = useSelector(selectEnvironmentOrganizations);
  const churnRecord = useSelector(selectChurnRecord);
  const minDate = addDays(new Date(), 2);

  const sourceEnvironments = Object.keys(Environments).filter(
    (env) => !["ondemand"].includes(env)
  );

  const product = "enroll";

  const initialFormState = {
    environment: "",
    organization: null,
    dateToRemove: addDays(new Date(), 2),
  };

  const [formValues, setFormValues] = React.useState(initialFormState);
  const [submittedFormData, setSubmittedFormData] = React.useState(null);
  const [modalOpen, setModalOpen] = React.useState(false);

  React.useEffect(() => {
    sourceEnvironments.forEach((environment) => {
      if (
        !environmentOrganizations.hasOwnProperty(product) ||
        !environmentOrganizations[product].hasOwnProperty(environment)
      ) {
        dispatch(
          fetchOrganizationsForEnvironmentRequested({
            environment,
            product,
          })
        );
      }
    });

    return () => {
      dispatch(resetSaveState());
    };
    // eslint-disable-next-line
  }, [dispatch, isOrganizationLoaded]);

  const buildSchema = () =>
    yup.object().shape({
      environment: yup.object().required("Required").nullable(),
      organization: yup
        .object()
        .shape({
          id: yup.string(),
          subdomain: yup.string(),
        })
        .typeError("Required")
        .required("Required"),
      dateToRemove: yup
        .date()
        .min(new Date(), "Churn date cannot be in the past")
        .typeError("Required")
        .required("Required"),
    });

  const {
    handleSubmit,
    control,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(buildSchema()),
  });

  const buildEnvironmentOptions = () => {
    const options = sourceEnvironments
      .filter(
        (env) =>
          environmentOrganizations["enroll"].hasOwnProperty(env) &&
          environmentOrganizations["enroll"][env].length > 0
      )
      .map((env) => ({
        value: env,
        label: env.charAt(0).toUpperCase() + env.slice(1),
      }));

    return options.sort((a, b) => -b.label.localeCompare(a.label));
  };

  const buildOrganizationOptions = () => {
    if (
      isOrganizationFetching ||
      !formValues.environment ||
      !environmentOrganizations.hasOwnProperty("enroll") ||
      !environmentOrganizations["enroll"].hasOwnProperty(formValues.environment)
    ) {
      return [];
    }

    const options = environmentOrganizations["enroll"][
      formValues.environment
    ].map((org) => ({
      value: org.id,
      label: `${org.id} - ${org.organization_name} (${org.subdomain})`,
      subdomain: org.subdomain,
      organization_name: org.organization_name,
      namespace: org.namespace
    }));

    return options;
  };

  const handleFieldChange = (input, newValue) => {
    let updatedFormValues = {
      ...formValues,
      [input]: newValue,
    };

    if (input === "environment") {
      updatedFormValues = {
        ...formValues,
        [input]: newValue,
      };
      setValue("organization", null);
    }

    setFormValues(updatedFormValues);
  };

  const handleOrganizationChange = (_, selectedValue) => {
    handleFieldChange("organization", {
      id: selectedValue.value,
      subdomain: selectedValue.subdomain,
      namespace: selectedValue.namespace
    });
  };

  const handleConfirmation = (formData) => {
    setSubmittedFormData(formData);
    setModalOpen(true);
  };

  const handleScheduleChurn = () => {
    setModalOpen(false);
    let payload = {
      platform_stage: process.env.REACT_APP_ENVIRONMENT,
      user: userProfile.email,
    };
    Object.keys(submittedFormData).forEach((inputKey) => {
      if (inputKey === "dateToRemove") {
        payload[inputKey] = DateFormat(
          submittedFormData[inputKey],
          DATE_TIME_FORMATS.outputDate
        );
      } else if (inputKey === "organization") {
        payload["orgId"] = submittedFormData[inputKey].value;
        payload["orgSubdomain"] = submittedFormData[inputKey].subdomain;
        payload["orgNamespace"] = submittedFormData[inputKey].namespace;
      } else {
        payload[inputKey] = submittedFormData[inputKey].value;
      }
    });
    dispatch(scheduleChurnRequested(payload));
  };

  const handleRefreshData = () => {
    dispatch(refreshOrganizationList());
  };

  const getErrorBanner = () => {
    if (error) {
      return (
        <Alert severity="error">
          <pre>{JSON.stringify(error, null, 2)}</pre>
        </Alert>
      );
    }
  };

  const getSuccessBanner = () => {
    if (savedSucceeded && isFinished && churnRecord.org_id) {
      return (
        <Alert severity="success">
          {churnRecord.org_id} - {churnRecord.org_subdomain} has been scheduled
          for deletion!
        </Alert>
      );
    }
  };

  const renderForm = () => (
    <Grid container justifyContent="center">
      <Grid item xs={6}>
        <Box width="inherit" mt={2}>
          <form onSubmit={handleSubmit(handleConfirmation)}>
            <Grid container direction="column" spacing={2}>
              <Grid item>
                <FormSelect
                  options={buildEnvironmentOptions()}
                  name="environment"
                  label="Source Environment"
                  errors={errors}
                  isDisabled={isScheduling || !isFinished}
                  onChange={handleFieldChange}
                  control={control}
                  description="Environment to remove the org from"
                />
              </Grid>
              <Grid item>
                <FormSelect
                  options={buildOrganizationOptions()}
                  name="organization"
                  label="Organization"
                  errors={errors}
                  isDisabled={isScheduling || !isFinished}
                  onChange={handleOrganizationChange}
                  control={control}
                  description="Organization to Churn"
                />
              </Grid>
              <Grid item>
                <FormDatePicker
                  name="dateToRemove"
                  label="Churn Date"
                  value={formValues.dateToRemove}
                  onChange={handleFieldChange}
                  control={control}
                  errors={errors}
                  disablePast={true}
                  minDate={minDate}
                  isDisabled={isScheduling || !isFinished}
                  defaultValue={formValues.dateToRemove}
                  description="Date to Remove this Organization"
                />
              </Grid>
              <Grid item>
                <Box>
                  <FormButton
                    type="submit"
                    variant="contained"
                    color="primary"
                    fullWidth
                    value={
                      isFinished ? "Schedule Churn" : "Scheduling Churn..."
                    }
                    isSubmitting={isScheduling || !isFinished}
                    disableElevation
                  />
                </Box>
              </Grid>
            </Grid>
          </form>
        </Box>
      </Grid>
    </Grid>
  );

  return (
    <>
      <ModalAlert
        open={modalOpen}
        title="Schedule Churn"
        agreeText="Schedule"
        disagreeText="Cancel"
        innerHTML={
          "Are you sure you want to schedule this organization for deletion?<br><br>Once the deletion is complete, data will be UNABLE to be restored."
        }
        onAgree={handleScheduleChurn}
        onDisagree={() => setModalOpen(false)}
        onClose={() => setModalOpen(false)}
      />
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
      >
        <Grid container item justifyContent="space-between" xs={6}>
          <Grid item>
            <Box width="inherit" mt={3}>
              <Typography variant="h4" align="center" color="primary">
                Schedule Organization Churn
              </Typography>
            </Box>
          </Grid>
          <Grid item>
            <Box width="inherit" mt={2}>
              <Tooltip title="Refresh Lists">
                <IconButton
                  color="primary"
                  onClick={handleRefreshData}
                  size="large"
                >
                  <RefreshIcon />
                </IconButton>
              </Tooltip>
            </Box>
          </Grid>
        </Grid>

        <Grid container alignItems="center" justifyContent="center">
          <Grid item lg={6} sm={12}>
            {getErrorBanner()}
            {getSuccessBanner()}
          </Grid>
        </Grid>

        {isOrganizationLoaded ? (
          renderForm()
        ) : (
          <Grid
            container
            justify-xs-center="true"
            direction="row"
            className={classes.container}
          >
            <Grid
              container
              alignItems="center"
              justifyContent="center"
              style={{ minHeight: "100vh" }}
            >
              <Grid item>
                <CircularProgress />
              </Grid>
            </Grid>
          </Grid>
        )}
      </Grid>
    </>
  );
};

export default ChurnOrganization;
