import React, { useRef } from 'react';
import { Controller } from 'react-hook-form';
import PropTypes from 'prop-types';
import { get as _get } from 'lodash';
import { Box, FormHelperText, TextField } from '@mui/material';
import { format as DateFormat } from 'date-fns';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import { toDateTime } from 'common/helpers/utils';
import { DATE_TIME_FORMATS } from 'common/constants';
import useStyles from './style';

function FormDatePicker({
    format,
    defaultValue,
    name,
    control,
    label,
    description,
    errors,
    value,
    minDate,
    maxDate,
    isDisabled,
    onChange: onChangeProp,
    disableToolbar,
    size,
    autoClose,
    disablePast,
    disableFuture,
}) {
    const classes = useStyles();
    const inputRef = useRef(null);
    const isError = _get(errors, name) !== undefined;
    const descriptionId = `${name}_description`
    
    return (
        <Box mt={1} mb={1}>
          <Controller
            control={control}
            name={name}
            defaultValue={defaultValue || null}
            onFocus={() => {
              inputRef.current.focus();
            }}
            render={({field}) => {
              return (
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DatePicker
                        id={name}
                        fullWidth
                        disableToolbar={disableToolbar}
                        variant="inline"
                        inputVariant="standard"
                        format={format}
                        error={isError}
                        value={value}
                        onChange={(data) => {
                            field.onChange(data);
                            if (onChangeProp) {
                                if (data) {
                                  onChangeProp(name, data);
                                } else {
                                  // form clear option
                                  onChangeProp(name, '');
                                }                              
                            }
                          }}
                        KeyboardButtonProps={{
                        'aria-label': 'change date',
                        }}
                        size={size}
                        autoOk={autoClose}
                        maxDate={maxDate}
                        minDate={minDate}
                        disabled={isDisabled}
                        disablePast={disablePast}
                        disableFuture={disableFuture}
                        maxDateMessage={`Date selected cannot be past ${DateFormat(maxDate, DATE_TIME_FORMATS.date)}`}
                        minDateMessage={`Date selected cannot be before ${DateFormat(minDate, DATE_TIME_FORMATS.date)}`}
                        renderInput={(props) => <TextField {...props} variant="standard" /> }
                    />
                </LocalizationProvider>
              );
            }}
          />
          <FormHelperText id={descriptionId}>
            { description }
          </FormHelperText>
          {isError && (
            <FormHelperText className={classes.error}>
              {_get(errors, name).message}
            </FormHelperText>
          )}
        </Box>
      );
};

FormDatePicker.propType = {
    format: PropTypes.string,
    defaultValue: PropTypes.string,
    name: PropTypes.string,
    control: PropTypes.any,
    label: PropTypes.string,
    description: PropTypes.string,
    errors: PropTypes.object,
    isDisabled: PropTypes.bool,
    disableToolbar: PropTypes.bool,
    onChange: PropTypes.func,
    size: PropTypes.string,
    autoClose: PropTypes.bool,
    disablePast: PropTypes.bool,
    disableFuture: PropTypes.bool,
};

FormDatePicker.defaultProps = {
    format: DATE_TIME_FORMATS.date,
    defaultValue: "",
    onChange: () => {},
    disableToolbar: true,
    size: 'small',
    autoClose: true,
    maxDate: toDateTime(DATE_TIME_FORMATS.maxDate),
    minDate: toDateTime(DATE_TIME_FORMATS.minDate),
    disablePast: false,
    disableFuture: false,
};

export default FormDatePicker;