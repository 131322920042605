export const DATE_TIME_FORMATS = {
    time: 'HH:mm:ss',
    timeAmPm: 'hh:mm a',
    date: 'MM/dd/yyyy',
    outputDate: 'yyyy-MM-dd',
    hyphenDate: 'yyyy-MM-dd',
    stringDate: 'MMMM dd, yyyy', // 2021 March 24
    dateTime: 'MM/dd/yyyy hh:mm a',
    fullDateTime: 'MM/dd/yyyy hh:mm:ss a',
    importDate: 'MM/dd/yyyy HH:mm',
    outputDateTime: 'yyyy-MM-dd HH:mm:ss',
    maxDate: '2100-01-01',
    minDate: '1900-01-01',
    eventDate: 'E, MMM do, yyyy', // 'Mon, Jun 28th, 2021'
  };